import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { collection, getDocs, updateDoc, doc, orderBy, query } from 'firebase/firestore';
import Switch from 'react-switch';
import defaultProfile from '../../../images/logo.png';

function Promotions() {
  const [shops, setShops] = useState([]);

  useEffect(() => {
    const fetchShops = async () => {
      const shopsCollection = collection(db, 'Shops');
      const shopsQuery = query(shopsCollection, orderBy('promoCreatedTime', 'desc'));
      const shopsSnapshot = await getDocs(shopsQuery);
      const shopsList = shopsSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));

      const filteredShops = shopsList.filter(shop => shop.promoImage);
      setShops(filteredShops);
    };

    fetchShops();
  }, []);

  const handleSwitchChange = async (shopId, newStatus) => {
    const userConfirmed = window.confirm(
      `Are you sure you want to ${newStatus ? 'verify' : 'unverify'} this promotion?`
    );

    if (!userConfirmed) {
      return;
    }

    if (!shopId) {
      console.error('Invalid shop ID');
      return;
    }

    try {
      const shopRef = doc(db, 'Shops', shopId);
      await updateDoc(shopRef, {
        isPromoVerified: newStatus,
      });

      setShops((prevShops) =>
        prevShops.map((shop) =>
          shop.id === shopId
            ? { ...shop, isPromoVerified: newStatus }
            : shop
        )
      );
    } catch (error) {
      console.error('Error updating promo verified status:', error);
    }
  };

  // Time difference calculation
  const timeDifference = (promoCreatedTime) => {
    const now = new Date();
    const promoTime = new Date(promoCreatedTime.seconds * 1000);
    const differenceInMs = now - promoTime;

    const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  };

  return (
    <div style={styles.container}>
      {shops.length > 0 ? (
        <ul style={styles.list}>
          {shops.map((shop, index) => (
            <li key={index} style={styles.listItem}>
              <div style={styles.leftSide}>
                <h4 style={styles.shopName}>{shop.shopName || shop.ownerName}</h4>
                <p style={styles.phone}>Phone: {shop.phone}</p>
                <p style={styles.promoDetails}>
                  <strong>Promo Created:</strong> {new Date(shop.promoCreatedTime.seconds * 1000).toLocaleString()} ({timeDifference(shop.promoCreatedTime)})
                </p>
                <p style={styles.promoDetails}>
                  <strong>Promo Count:</strong> {shop.promoCount || 0}
                </p>
                <p style={styles.promoDetails}>
                  <strong>Promo Verified:</strong>
                  <Switch
                    checked={shop.isPromoVerified}
                    onChange={(checked) => handleSwitchChange(shop.id, checked)}
                    offColor="#888"
                    onColor="#4CAF50"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    height={20}
                    width={40}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </p>
              </div>
              <div style={styles.rightSide}>
                <img
                  src={shop.promoImage}
                  alt="Promo"
                  style={styles.promoImage}
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p style={styles.noShops}>No promotions available</p>
      )}
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    boxSizing: 'border-box',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    marginBottom: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  leftSide: {
    flex: 1,
    paddingRight: '15px',
  },
  rightSide: {
    width: '600px',
  },
  promoImage: {
    width: '600px',
    height: '200px',
    borderRadius: '4px',
    border: '1px solid #e0e0e0',
    objectFit: 'cover',
  },
  promoDetails: {
    margin: '5px 0',
    color: '#444',
  },
  shopName: {
    fontSize: '1.1em',
    margin: '0 0 5px',
    color: '#333',
  },
  phone: {
    margin: 0,
    color: '#666',
  },
  noShops: {
    textAlign: 'center',
    color: '#999',
    fontStyle: 'italic',
  },
};

export default Promotions;
