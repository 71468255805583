import React from 'react';

function Favorites() {
  return (
    <div>
      <h3>Favorites</h3>
      <p>Manage your favorites here.</p>
    </div>
  );
}

export default Favorites;
