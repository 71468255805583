import React from 'react';

function Settings() {
  return (
    <div>
      <h3>Settings</h3>
      <p>Manage your settings here.</p>
    </div>
  );
}

export default Settings;
