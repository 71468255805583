import React, { useState } from "react";
import "./css/ThinMenu.css";
import categories from '../js/categories';
import * as FaIcons from "react-icons/fa";

const ThinMenu = ({ onSubCategoryClick }) => {
  const [expandedCategory, setExpandedCategory] = useState(null);

  const icons = {
    "Home Services": <FaIcons.FaWrench />,
    "Personal Care": <FaIcons.FaUserAlt />,
    "Health Care": <FaIcons.FaStethoscope />,
    "Medical Shops": <FaIcons.FaPrescriptionBottle />,
    "Automobile Services": <FaIcons.FaCarAlt />,
    "Electronics Repair": <FaIcons.FaCogs />,
    "Food & Groceries": <FaIcons.FaShoppingBasket />,
    "Other Services": <FaIcons.FaTools />,
  };

  const handleCategoryHover = (category) => {
    setExpandedCategory(category);
  };

  const handleMouseLeave = () => {
    setExpandedCategory(null);
  };

  const handleSubCategoryClick = (subCategoryLabel) => {
    onSubCategoryClick(subCategoryLabel); // Call the parent handler
    setExpandedCategory(null); // Close the menu after selection
  };

  return (
    <div className="thin-menu" onMouseLeave={handleMouseLeave}>
      <div className="menu-bar">
        {Object.keys(categories).map((category, index) => (
          <div
            key={index}
            className="menu-item"
            onMouseEnter={() => handleCategoryHover(category)}
          >
            <span className="menu-icon">{icons[category]}</span>
            <span className="menu-text">{category}</span>
          </div>
        ))}
      </div>
      {expandedCategory && (
        <div className="mega-menu">
          {categories[expandedCategory].map((subCategory, index) => (
            <div key={index} className="sub-category">
              <span className="sub-category-icon">{subCategory.icon}</span>
              <h4>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubCategoryClick(subCategory.label); // Close the menu after selection
                  }}
                >
                  {subCategory.label}
                </a>
              </h4>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThinMenu;
