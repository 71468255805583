import React, { createContext, useState } from "react";

// Create context
export const LocationContext = createContext();

// LocationProvider component to wrap the app and provide location context
const LocationProvider = ({ children }) => {
  // Initialize state with city, country, latitude, and longitude
  const [location, setLocation] = useState({
    city: null,
    country: null,
    latitude: null,
    longitude: null,
  });

  // Return the provider with location and setLocation in the value
  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
