import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import defaultProfile from '../../../images/logo.png';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Tickets() {
  const [tickets, setTickets] = useState([]);
  const [shops, setShops] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchTicketsAndShops = async () => {
      // Fetch tickets
      const ticketsCollection = collection(db, 'Tickets');
      const ticketsSnapshot = await getDocs(ticketsCollection);
      const ticketsList = ticketsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch shops
      const shopsCollection = collection(db, 'Shops');
      const shopsSnapshot = await getDocs(shopsCollection);
      const shopsList = shopsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setTickets(ticketsList);
      setShops(shopsList);
    };

    fetchTicketsAndShops();
  }, []);

  const openModal = (ticket) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTicket(null);
    setIsModalOpen(false);
  };

  const getShopDetails = (shopId) => {
    return shops.find(shop => shop.uid === shopId) || {};
  };

  return (
    <div style={styles.container}>
      <ul style={styles.list}>
        {tickets.length > 0 ? (
          tickets.map((ticket, index) => {
            const shopDetails = getShopDetails(ticket.shopId);
            return (
              <li key={index} style={styles.listItem} onClick={() => openModal(ticket)}>
                <div style={styles.info}>
                  <h4 style={styles.shopName}>{shopDetails.shopName || shopDetails.ownerName || "Unknown Shop"}</h4>
                  <p style={styles.address}>Address: {shopDetails.address || "No address available"}</p>
                  <p style={styles.phone}>Phone: {shopDetails.phone || "No phone available"}</p>
                </div>
              </li>
            );
          })
        ) : (
          <p style={styles.noUsers}>No tickets found</p>
        )}
      </ul>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Ticket Details"
        style={modalStyles}
      >
        {selectedTicket && (
          <div>
            <h2>Ticket Details</h2>
            <p><strong>Message:</strong> {selectedTicket.message || 'No message'}</p>
            <p><strong>Shop ID:</strong> {selectedTicket.shopId}</p>
            <p><strong>Created At:</strong> {new Date(selectedTicket.createdAt).toLocaleString()}</p>
            <button onClick={closeModal} style={styles.closeButton}>Close</button>
          </div>
        )}
      </Modal>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    boxSizing: 'border-box',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    marginBottom: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  shopName: {
    fontSize: '1.1em',
    margin: '0 0 5px',
    color: '#333',
  },
  address: {
    margin: '0 0 5px',
    color: '#666',
  },
  phone: {
    margin: 0,
    color: '#666',
  },
  noUsers: {
    textAlign: 'center',
    color: '#999',
    fontStyle: 'italic',
  },
  closeButton: {
    marginTop: '15px',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '500px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
};

export default Tickets;
