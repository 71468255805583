import React from 'react';
import './css/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a href="/aboutus">About Us</a>
        <a href="/contactus">Contact Us</a>
        <a href="/privacypolicy">Privacy Policy</a>
        <a href="/termscondition">Terms & Conditions</a>
        <a href="/faq">FAQs</a>
        <a href="/support">Customer Support</a>
        <a href="/howitworks">How It Works</a>
        <a href="/partnerships">Partnerships</a>
        <a href="/careers">Careers</a>
      </div>
      <p>&copy; 2024 Jift.io - All rights reserved.</p>
      <p className="footer-note">
        Discover the best shops and services near you. Connect directly via phone, email, or visit in person. Your local discovery platform!
      </p>
    </footer>
  );
};

export default Footer;
