import React from 'react';

function ShopSetupTickets() {
  return (
    <div>
      <h3>ShopSetupTickets</h3>
      <p>Manage your ShopSetupTickets here.</p>
    </div>
  );
}

export default ShopSetupTickets;
