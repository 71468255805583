import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase'; // Import the Firestore DB instance
import { collection, getDocs } from 'firebase/firestore';

function Users() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'Users'); // Reference to Users collection
      const usersSnapshot = await getDocs(usersCollection); // Get all documents
      const usersList = usersSnapshot.docs.map(doc => doc.data()); // Map to get user data
      setUsers(usersList); // Set the state with the fetched data
    };

    fetchUsers();
  }, []);

  return (
    <div>
      <ul>
        {users.length > 0 ? (
          users.map((user, index) => (
            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
              <img 
                src={user.profilePic} 
                alt="Profile" 
                style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }} 
              />
              <div>
                <h4>{user.userName}</h4>
                <p>Phone: {user.userPhone}</p>
                <p>Joined: {user.joinedDate.toDate().toLocaleString()}</p>
              </div>
            </li>
          ))
        ) : (
          <p>No users found</p>
        )}
      </ul>
    </div>
  );
}

export default Users;
