import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import Header from "./Header";
import Footer from "./Footer";
import "./css/ShopDetail.css";
import defaultImage from "../images/logo.png"; // Adjust path as necessary

const ShopDetail = () => {
  const { id } = useParams();
  const [shop, setShop] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!id) {
      setError("Shop ID is missing.");
      setLoading(false);
      return;
    }

    const fetchShopDetail = async () => {
      setLoading(true);
      try {
        const shopRef = doc(db, "Shops", id);
        const shopDoc = await getDoc(shopRef);
        if (shopDoc.exists()) {
          setShop({ id: shopDoc.id, ...shopDoc.data() });
        } else {
          setError("Shop not found.");
        }
      } catch (error) {
        console.error("Error fetching shop details:", error);
        setError("Failed to load shop details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchShopDetail();
  }, [id]);

  if (loading) return <div className="loader">Loading...</div>;

  return (
    <>
      <Helmet>
        <title>
          {shop
            ? `${
                shop.shopName ||
                shop.ownerName + " " + shop.categories?.join(", ")
              } - ${shop.city}`
            : "Shop Details"}
        </title>
        <meta
          name="description"
          content={
            shop
              ? `${shop.shopName} - ${
                  shop.bio || "Discover details about this shop."
                }`
              : "Details about the shop"
          }
        />
        <meta
          name="keywords"
          content={`${shop ? shop.categories?.join(", ") : ""}, ${
            shop?.city || ""
          }, ${shop?.shopName || ""}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: shop?.shopName,
            image: shop?.shopImage || shop?.profilePic || defaultImage,
            address: {
              "@type": "PostalAddress",
              streetAddress: shop?.address,
              addressLocality: shop?.city,
              addressRegion: shop?.state,
              postalCode: shop?.zipCode,
            },
            telephone: shop?.phone,
            openingHoursSpecification: Object.entries(shop?.timings || {}).map(
              ([day, timings]) => ({
                "@type": "OpeningHoursSpecification",
                dayOfWeek: day,
                opens: timings.status !== "closed" ? timings.open : undefined,
                closes: timings.status !== "closed" ? timings.close : undefined,
              })
            ),
            priceRange: "$",
            geo: {
              "@type": "GeoCoordinates",
              latitude: shop?.latitude,
              longitude: shop?.longitude,
            },
            url: window.location.href,
          })}
        </script>
      </Helmet>

      <Header />

      <div className="shop-detail-container">
        {error ? (
          <div className="error">{error}</div>
        ) : (
          <div className="shop-content">
            {/* Shop Details Section */}
            <div className="shop-detail">
              <div className="shop-header-details">
                {shop?.shopImage ? (
                  <div
                    className="full-background"
                    style={{
                      backgroundImage: `url(${shop.shopImage})`,
                    }}
                  >
                    <h1>{shop.shopName || shop.ownerName}</h1>
                    {shop.isshopVerified && (
                      <span className="verified-icon" title="Verified Shop">
                        <i className="fas fa-check-circle"></i>
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="split-header">
                    <div className="header-image">
                      <img
                        src={shop?.profilePic || defaultImage}
                        alt={`${shop.shopName} Profile`}
                      />
                    </div>
                    <div className="header-info">
                      <h1>{shop.shopName || shop.ownerName}</h1>
                      {shop.isshopVerified && (
                        <span className="verified-icon" title="Verified Shop">
                          <i className="fas fa-check-circle"></i>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Category Labels */}
              <div className="category-labels">
                {shop.categories?.length > 0 ? (
                  shop.categories.map((category) => (
                    <span key={category} className="category-label">
                      {category}
                    </span>
                  ))
                ) : (
                  <span>No categories available.</span>
                )}
              </div>

              {/* Icons Section */}
              <div className="icon-section">
                <button
                  className="icon-button"
                  onClick={() => setShowModal(true)}
                >
                  <i className="fas fa-phone-alt"></i>
                </button>
                <button className="icon-button">
                  <i className="fas fa-map-marker-alt"></i>
                </button>
              </div>

              {/* Bio Section */}
              <div className="shop-info-section">
                <p>{shop.bio || "No bio available."}</p>
              </div>

              {/* Address Section */}
              <div className="shop-info-section">
                <p>
                  <strong>Address:</strong>{" "}
                  {`${shop.address}, ${shop.city}, ${shop.state}, ${shop.zipCode}`}
                </p>
              </div>

              {/* Business Details */}
              <div className="shop-info-section">
                <div className="business-details">
                  <p>
                    <strong>Delivery:</strong>{" "}
                    {shop.delivery ? (
                      <span>
                        <i className="fas fa-truck delivery-icon"></i>
                      </span>
                    ) : (
                      " Home Delivery Not Available"
                    )}
                  </p>
                  <p>
                    <strong>Pickup:</strong>{" "}
                    {shop.pickup ? (
                      <i className="fas fa-shopping-bag pickup-icon"></i>
                    ) : (
                      "Pickup Not Available"
                    )}
                  </p>
                  <p>
                    <strong>Online Status:</strong>{" "}
                    {shop.onlineStatus ? "Online" : "Offline"}
                  </p>
                  <p>
                    <strong>Verified:</strong>{" "}
                    {shop.isshopVerified ? "Yes" : "No"}
                  </p>
                </div>
              </div>

              {/* Shop Timings */}
              <div className="shop-info-section">
                <ul>
                  {Object.entries(shop.timings || {}).map(([day, timings]) => (
                    <li key={day}>
                      <strong>
                        {day.charAt(0).toUpperCase() + day.slice(1)}:
                      </strong>
                      {timings.status === "closed"
                        ? " Closed"
                        : ` ${timings.open} - ${timings.close}`}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Right Section - Chat Box and Shop Images Gallery Side by Side */}
            <div className="right-section" style={{width:"50%"}}>
              <div className="chat-and-gallery">
                {/* Chat Box */}
                <div className="chat-box">
                  <h2>Chat with {shop?.shopName || "the shop"}</h2>
                  <div className="chat-messages">
                    Feature Coming Soon to Web! To chat directly with the shop,
                    please download our Android app.
                  </div>
                  <input
                    type="text"
                    placeholder="Type your message..."
                    disabled
                  />
                  <button disabled>Send</button>
                  <button
                    className="android-app-button"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=your.app.id",
                        "_blank"
                      )
                    }
                  >
                    Download Android App
                  </button>
                </div>

                {/* Shop Images Gallery */}
                <div className="shop-images-gallery">
                  <div className="gallery-images">
                    {shop.shopPic && shop.shopPic.length > 0 ? (
                      shop.shopPic.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`${shop.shopName} Image ${index + 1}`}
                          className="gallery-image"
                        />
                      ))
                    ) : (
                      <p>No images available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal for Phone Number */}
        {showModal && (
          <div className="modal-overlay" onClick={() => setShowModal(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <h4>Shop Phone Number</h4>
              <p>{shop?.phone || "Phone number not available"}</p>
              <button onClick={() => setShowModal(false)}>Close</button>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default ShopDetail;
