const country = {
  Afghanistan: {
    cities: ['Kabul', 'Herat', 'Kandahar', 'Mazar-i-Sharif', 'Jalalabad'],
    currency: 'Afghan Afghani',
    symbol: '؋',
    exchangeRate: 90, // Example: 1 USD = 90 Afghan Afghani
    callingCode: '+93',
  },
  Albania: {
    cities: ['Tirana', 'Durrës', 'Vlorë', 'Shkodër', 'Fier'],
    currency: 'Albanian Lek',
    symbol: 'L',
    exchangeRate: 100, // Example: 1 USD = 100 Albanian Lek
    callingCode: '+355',
  },
  Algeria: {
    cities: ['Algiers', 'Oran', 'Constantine', 'Annaba', 'Blida'],
    currency: 'Algerian Dinar',
    symbol: 'دج',
    exchangeRate: 130, // Example: 1 USD = 130 Algerian Dinar
    callingCode: '+213',
  },
  Andorra: {
    cities: ['Andorra la Vella', 'Escaldes-Engordany', 'Encamp', 'Sant Julià de Lòria', 'La Massana'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+376',
  },
  Angola: {
    cities: ['Luanda', 'Huambo', 'Lobito', 'Benguela', 'Lubango'],
    currency: 'Angolan Kwanza',
    symbol: 'Kz',
    exchangeRate: 500, // Example: 1 USD = 500 Angolan Kwanza
    callingCode: '+244',
  },
  Argentina: {
    cities: ['Buenos Aires', 'Córdoba', 'Rosario', 'Mendoza', 'La Plata'],
    currency: 'Argentine Peso',
    symbol: '$',
    exchangeRate: 350, // Example: 1 USD = 350 Argentine Pesos
    callingCode: '+54',
  },
  Armenia: {
    cities: ['Yerevan', 'Gyumri', 'Vanadzor', 'Hrazdan', 'Abovyan'],
    currency: 'Armenian Dram',
    symbol: '֏',
    exchangeRate: 480, // Example: 1 USD = 480 Armenian Drams
    callingCode: '+374',
  },
  Australia: {
    cities: ['Sydney', 'Melbourne', 'Brisbane', 'Perth', 'Adelaide'],
    currency: 'Australian Dollar',
    symbol: 'A$',
    exchangeRate: 1.5, // Example: 1 USD = 1.5 Australian Dollars
    callingCode: '+61',
  },
  Austria: {
    cities: ['Vienna', 'Graz', 'Linz', 'Salzburg', 'Innsbruck'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+43',
  },
  Azerbaijan: {
    cities: ['Baku', 'Ganja', 'Sumqayit', 'Mingachevir', 'Sheki'],
    currency: 'Azerbaijani Manat',
    symbol: '₼',
    exchangeRate: 1.7, // Example: 1 USD = 1.7 Azerbaijani Manat
    callingCode: '+994',
  },
  Bahamas: {
    cities: ['Nassau', 'Freeport', 'West End', 'Cooper’s Town', 'Marsh Harbour'],
    currency: 'Bahamian Dollar',
    symbol: '$',
    exchangeRate: 1, // Example: 1 USD = 1 Bahamian Dollar
    callingCode: '+1-242',
  },
  Bahrain: {
    cities: ['Manama', 'Riffa', 'Muharraq', 'Hamad Town', 'Sitra'],
    currency: 'Bahraini Dinar',
    symbol: '.د.ب',
    exchangeRate: 0.38, // Example: 1 USD = 0.38 Bahraini Dinar
    callingCode: '+973',
  },
  Barbados: {
    cities: ['Bridgetown', 'Speightstown', 'Holetown', 'Oistins', 'Bathsheba'],
    currency: 'Barbadian Dollar',
    symbol: 'Bds$',
    exchangeRate: 2, // Example: 1 USD = 2 Barbadian Dollars
    callingCode: '+1-246',
  },
  Bhutan: {
    cities: ['Thimphu', 'Phuntsholing', 'Punakha', 'Gelephu', 'Trongsa'],
    currency: 'Bhutanese Ngultrum',
    symbol: 'Nu.',
    exchangeRate: 75, // Example: 1 USD = 75 Bhutanese Ngultrum (pegged to INR)
    callingCode: '+975',
  },
  Bolivia: {
    cities: ['La Paz', 'Santa Cruz', 'Cochabamba', 'Oruro', 'Sucre'],
    currency: 'Bolivian Boliviano',
    symbol: 'Bs.',
    exchangeRate: 6.9, // Example: 1 USD = 6.9 Bolivian Bolivianos
    callingCode: '+591',
  },
  Botswana: {
    cities: ['Gaborone', 'Francistown', 'Maun', 'Serowe', 'Molepolole'],
    currency: 'Botswana Pula',
    symbol: 'P',
    exchangeRate: 11, // Example: 1 USD = 11 Botswana Pula
    callingCode: '+267',
  },
  Bangladesh: {
    cities: ['Dhaka', 'Chittagong', 'Khulna', 'Rajshahi', 'Sylhet'],
    currency: 'Bangladeshi Taka',
    symbol: '৳',
    exchangeRate: 85, // Example: 1 USD = 85 Bangladeshi Taka
    callingCode: '+880',
  },
  Belarus: {
    cities: ['Minsk', 'Gomel', 'Mogilev', 'Vitebsk', 'Grodno'],
    currency: 'Belarusian Ruble',
    symbol: 'Br',
    exchangeRate: 2.6, // Example: 1 USD = 2.6 Belarusian Rubles
    callingCode: '+375',
  },
  Belgium: {
    cities: ['Brussels', 'Antwerp', 'Ghent', 'Charleroi', 'Liège'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+32',
  },
  Brazil: {
    cities: [
      'São Paulo',
      'Rio de Janeiro',
      'Brasília',
      'Salvador',
      'Fortaleza',
    ],
    currency: 'Brazilian Real',
    symbol: 'R$',
    exchangeRate: 5, // Example: 1 USD = 5 Brazilian Reals
    callingCode: '+55',
  },
  Canada: {
    cities: ['Toronto', 'Montreal', 'Vancouver', 'Calgary', 'Edmonton'],
    currency: 'Canadian Dollar',
    symbol: 'C$',
    exchangeRate: 1.35, // Example: 1 USD = 1.35 Canadian Dollars
    callingCode: '+1',
  },
  China: {
    cities: ['Shanghai', 'Beijing', 'Chongqing', 'Shenzhen', 'Guangzhou'],
    currency: 'Chinese Yuan',
    symbol: '¥',
    exchangeRate: 7, // Example: 1 USD = 7 Chinese Yuan
    callingCode: '+86',
  },
  Chile: {
    cities: [
      'Santiago',
      'Valparaíso',
      'Concepción',
      'La Serena',
      'Antofagasta',
    ],
    currency: 'Chilean Peso',
    symbol: '$',
    exchangeRate: 900, // Example: 1 USD = 900 Chilean Peso
    callingCode: '+56',
  },
  Egypt: {
    cities: ['Cairo', 'Alexandria', 'Giza', 'Luxor', 'Aswan'],
    currency: 'Egyptian Pound',
    symbol: 'E£',
    exchangeRate: 30, // Example: 1 USD = 30 Egyptian Pound
    callingCode: '+20',
  },
  Finland: {
    cities: ['Helsinki', 'Espoo', 'Tampere', 'Oulu', 'Vantaa'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+358',
  },
  France: {
    cities: ['Paris', 'Marseille', 'Lyon', 'Toulouse', 'Nice'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+33',
  },
  Georgia: {
    cities: ['Tbilisi', 'Batumi', 'Kutaisi', 'Rustavi', 'Zugdidi'],
    currency: 'Georgian Lari',
    symbol: '₾',
    exchangeRate: 3.5, // Example: 1 USD = 3.5 Georgian Lari
    callingCode: '+995',
  },
  Germany: {
    cities: ['Berlin', 'Munich', 'Frankfurt', 'Hamburg', 'Cologne'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+49',
  },
  Greece: {
    cities: ['Athens', 'Thessaloniki', 'Patras', 'Heraklion', 'Larissa'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+30',
  },
  Honduras: {
    cities: [
      'Tegucigalpa',
      'San Pedro Sula',
      'Choloma',
      'La Ceiba',
      'El Progreso',
    ],
    currency: 'Honduran Lempira',
    symbol: 'L',
    exchangeRate: 24, // Example: 1 USD = 24 Lempiras
    callingCode: '+504',
  },
  Iceland: {
    cities: ['Reykjavik', 'Akureyri', 'Keflavik', 'Selfoss', 'Hafnarfjörður'],
    currency: 'Icelandic Krona',
    symbol: 'kr',
    exchangeRate: 140, // Example: 1 USD = 140 Icelandic Krona
    callingCode: '+354',
  },
  India: {
    cities: ['Mumbai', 'Delhi', 'Bangalore', 'Kolkata', 'Chennai'],
    currency: 'Indian Rupee',
    symbol: '₹',
    exchangeRate: 75, // Example: 1 USD = 75 Indian Rupees
    callingCode: '+91',
  },
  Indonesia: {
    cities: ['Jakarta', 'Surabaya', 'Bandung', 'Medan', 'Bekasi'],
    currency: 'Indonesian Rupiah',
    symbol: 'Rp',
    exchangeRate: 15000, // Example: 1 USD = 15,000 Indonesian Rupiah
    callingCode: '+62',
  },
  Italy: {
    cities: ['Rome', 'Milan', 'Naples', 'Turin', 'Palermo'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+39',
  },
  Japan: {
    cities: ['Tokyo', 'Osaka', 'Nagoya', 'Sapporo', 'Fukuoka'],
    currency: 'Japanese Yen',
    symbol: '¥',
    exchangeRate: 110, // Example: 1 USD = 110 Japanese Yen
    callingCode: '+81',
  },
  Kyrgyzstan: {
    cities: ['Bishkek', 'Osh', 'Jalal-Abad', 'Karakol', 'Tokmok'],
    currency: 'Kyrgyzstani Som',
    symbol: 'с',
    exchangeRate: 85, // Example: 1 USD = 85 Kyrgyzstani Som
    callingCode: '+996',
  },
  Kenya: {
    cities: ['Nairobi', 'Mombasa', 'Kisumu', 'Nakuru', 'Eldoret'],
    currency: 'Kenyan Shilling',
    symbol: 'KSh',
    exchangeRate: 140, // Example: 1 USD = 140 Kenyan Shilling
    callingCode: '+254',
  },
  Laos: {
    cities: ['Vientiane', 'Luang Prabang', 'Pakse', 'Savannakhet', 'Thakhek'],
    currency: 'Lao Kip',
    symbol: '₭',
    exchangeRate: 18000, // Example: 1 USD = 18,000 Lao Kip
    callingCode: '+856',
  },
  Madagascar: {
    cities: [
      'Antananarivo',
      'Toamasina',
      'Antsirabe',
      'Fianarantsoa',
      'Mahajanga',
    ],
    currency: 'Malagasy Ariary',
    symbol: 'Ar',
    exchangeRate: 4500, // Example: 1 USD = 4,500 Ariary
    callingCode: '+261',
  },
  Malaysia: {
    cities: [
      'Kuala Lumpur',
      'George Town',
      'Johor Bahru',
      'Kota Kinabalu',
      'Malacca',
    ],
    currency: 'Malaysian Ringgit',
    symbol: 'RM',
    exchangeRate: 4.5, // Example: 1 USD = 4.5 Malaysian Ringgit
    callingCode: '+60',
  },
  Mexico: {
    cities: ['Mexico City', 'Guadalajara', 'Monterrey', 'Puebla', 'Tijuana'],
    currency: 'Mexican Peso',
    symbol: '$',
    exchangeRate: 18, // Example: 1 USD = 18 Mexican Pesos
    callingCode: '+52',
  },
  Mongolia: {
    cities: ['Ulaanbaatar', 'Erdenet', 'Darkhan', 'Choibalsan', 'Ölgii'],
    currency: 'Mongolian Tögrög',
    symbol: '₮',
    exchangeRate: 3500, // Example: 1 USD = 3,500 Tögrög
    callingCode: '+976',
  },
  NewZealand: {
    cities: ['Auckland', 'Wellington', 'Christchurch', 'Hamilton', 'Dunedin'],
    currency: 'New Zealand Dollar',
    symbol: 'NZ$',
    exchangeRate: 1.6, // Example: 1 USD = 1.6 New Zealand Dollar
    callingCode: '+64',
  },
  Nigeria: {
    cities: ['Lagos', 'Abuja', 'Kano', 'Ibadan', 'Port Harcourt'],
    currency: 'Nigerian Naira',
    symbol: '₦',
    exchangeRate: 800, // Example: 1 USD = 800 Nigerian Naira
    callingCode: '+234',
  },
  Norway: {
    cities: ['Oslo', 'Bergen', 'Stavanger', 'Trondheim', 'Tromsø'],
    currency: 'Norwegian Krone',
    symbol: 'kr',
    exchangeRate: 10, // Example: 1 USD = 10 Norwegian Krone
    callingCode: '+47',
  },
  Pakistan: {
    cities: ['Karachi', 'Lahore', 'Islamabad', 'Faisalabad', 'Peshawar'],
    currency: 'Pakistani Rupee',
    symbol: '₨',
    exchangeRate: 280, // Example: 1 USD = 280 Pakistani Rupees
    callingCode: '+92',
  },
  Paraguay: {
    cities: [
      'Asunción',
      'Ciudad del Este',
      'Encarnación',
      'Luque',
      'San Lorenzo',
    ],
    currency: 'Paraguayan Guarani',
    symbol: '₲',
    exchangeRate: 7100, // Example: 1 USD = 7,100 Paraguayan Guarani
    callingCode: '+595',
  },
  Philippines: {
    cities: [
      'Manila',
      'Quezon City',
      'Cebu City',
      'Davao City',
      'Zamboanga City',
    ],
    currency: 'Philippine Peso',
    symbol: '₱',
    exchangeRate: 55, // Example: 1 USD = 55 Philippine Pesos
    callingCode: '+63',
  },
  Portugal: {
    cities: ['Lisbon', 'Porto', 'Braga', 'Coimbra', 'Funchal'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+351',
  },
  Qatar: {
    cities: ['Doha', 'Al Wakrah', 'Al Rayyan', 'Umm Salal', 'Al Khor'],
    currency: 'Qatari Riyal',
    symbol: '﷼',
    exchangeRate: 3.64, // Example: 1 USD = 3.64 Qatari Riyal
    callingCode: '+974',
  },
  Russia: {
    cities: [
      'Moscow',
      'Saint Petersburg',
      'Novosibirsk',
      'Yekaterinburg',
      'Kazan',
    ],
    currency: 'Russian Ruble',
    symbol: '₽',
    exchangeRate: 80, // Example: 1 USD = 80 Russian Rubles
    callingCode: '+7',
  },
  Rwanda: {
    cities: ['Kigali', 'Butare', 'Gisenyi', 'Ruhengeri', 'Byumba'],
    currency: 'Rwandan Franc',
    symbol: 'FRw',
    exchangeRate: 1200, // Example: 1 USD = 1,200 Rwandan Franc
    callingCode: '+250',
  },
  SaudiArabia: {
    cities: ['Riyadh', 'Jeddah', 'Mecca', 'Medina', 'Dammam'],
    currency: 'Saudi Riyal',
    symbol: '﷼',
    exchangeRate: 3.75, // Example: 1 USD = 3.75 Saudi Riyal
    callingCode: '+966',
  },
  Serbia: {
    cities: ['Belgrade', 'Novi Sad', 'Niš', 'Kragujevac', 'Subotica'],
    currency: 'Serbian Dinar',
    symbol: 'дин',
    exchangeRate: 110, // Example: 1 USD = 110 Serbian Dinar
    callingCode: '+381',
  },
  SouthKorea: {
    cities: ['Seoul', 'Busan', 'Incheon', 'Daegu', 'Daejeon'],
    currency: 'South Korean Won',
    symbol: '₩',
    exchangeRate: 1150, // Example: 1 USD = 1150 Won
    callingCode: '+82',
  },
  SouthAfrica: {
    cities: [
      'Johannesburg',
      'Cape Town',
      'Durban',
      'Pretoria',
      'Port Elizabeth',
    ],
    currency: 'South African Rand',
    symbol: 'R',
    exchangeRate: 18, // Example: 1 USD = 18 South African Rand
    callingCode: '+27',
  },
  Singapore: {
    cities: ['Singapore'], // Singapore is a city-state
    currency: 'Singapore Dollar',
    symbol: 'S$',
    exchangeRate: 1.35, // Example: 1 USD = 1.35 Singapore Dollar
    callingCode: '+65',
  },
  Spain: {
    cities: ['Madrid', 'Barcelona', 'Valencia', 'Seville', 'Bilbao'],
    currency: 'Euro',
    symbol: '€',
    exchangeRate: 0.9, // Example: 1 USD = 0.9 Euro
    callingCode: '+34',
  },
  Sweden: {
    cities: ['Stockholm', 'Gothenburg', 'Malmö', 'Uppsala', 'Västerås'],
    currency: 'Swedish Krona',
    symbol: 'kr',
    exchangeRate: 11, // Example: 1 USD = 11 Swedish Krona
    callingCode: '+46',
  },
  Switzerland: {
    cities: ['Zurich', 'Geneva', 'Basel', 'Bern', 'Lausanne'],
    currency: 'Swiss Franc',
    symbol: 'CHF',
    exchangeRate: 0.91, // Example: 1 USD = 0.91 Swiss Franc
    callingCode: '+41',
  },
  Thailand: {
    cities: ['Bangkok', 'Chiang Mai', 'Pattaya', 'Phuket', 'Khon Kaen'],
    currency: 'Thai Baht',
    symbol: '฿',
    exchangeRate: 35, // Example: 1 USD = 35 Thai Baht
    callingCode: '+66',
  },
  Turkey: {
    cities: ['Istanbul', 'Ankara', 'Izmir', 'Bursa', 'Antalya'],
    currency: 'Turkish Lira',
    symbol: '₺',
    exchangeRate: 27, // Example: 1 USD = 27 Turkish Lira
    callingCode: '+90',
  },
  UnitedArabEmirates: {
    cities: ['Dubai', 'Abu Dhabi', 'Sharjah', 'Al Ain', 'Ajman'],
    currency: 'UAE Dirham',
    symbol: 'د.إ',
    exchangeRate: 3.67, // Example: 1 USD = 3.67 UAE Dirham
    callingCode: '+971',
  },
  UnitedKingdom: {
    cities: ['London', 'Birmingham', 'Manchester', 'Glasgow', 'Leeds'],
    currency: 'British Pound Sterling',
    symbol: '£',
    exchangeRate: 0.75, // Example: 1 USD = 0.75 British Pounds
    callingCode: '+44',
  },
  UnitedStates: {
    cities: ['New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix'],
    currency: 'United States Dollar',
    symbol: '$',
    exchangeRate: 1, // 1 USD = 1 USD (reference)
    callingCode: '+1',
  },
  Uzbekistan: {
    cities: ['Tashkent', 'Samarkand', 'Bukhara', 'Andijan', 'Namangan'],
    currency: 'Uzbekistani Som',
    symbol: 'сўм',
    exchangeRate: 11000, // Example: 1 USD = 11,000 Uzbekistani Som
    callingCode: '+998',
  },
  Vietnam: {
    cities: ['Hanoi', 'Ho Chi Minh City', 'Da Nang', 'Hai Phong', 'Can Tho'],
    currency: 'Vietnamese Dong',
    symbol: '₫',
    exchangeRate: 23000, // Example: 1 USD = 23,000 Vietnamese Dong
    callingCode: '+84',
  },
  Zimbabwe: {
    cities: ['Harare', 'Bulawayo', 'Chitungwiza', 'Mutare', 'Gweru'],
    currency: 'Zimbabwean Dollar',
    symbol: 'Z$',
    exchangeRate: 4000, // Example: 1 USD = 4,000 Zimbabwean Dollar
    callingCode: '+263',
  },
};

export default country;
