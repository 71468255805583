import React, { useMemo, useState, useEffect } from "react";
import { FaCar, FaShoppingBasket } from "react-icons/fa";
import "./css/ShopCard.css";
import logo from "../images/logo.png";
import { haversineDistance } from "../utils/haversineDistance";
import { useNavigate } from "react-router-dom";

const ShopCard = ({ shop }) => { // Removed unnecessary `onClick` prop
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }
  }, []);

  const todayTiming = useMemo(() => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDay = days[new Date().getDay()];
    return shop.timings && shop.timings[currentDay]
      ? shop.timings[currentDay]
      : { open: "Closed", close: "Closed", status: "closed" };
  }, [shop.timings]);

  const distance = useMemo(() => {
    if (userLocation.latitude && userLocation.longitude) {
      const userCoords = {
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
      };
      const shopCoords = {
        latitude: shop.location._lat,
        longitude: shop.location._long,
      };

      return haversineDistance(userCoords, shopCoords).toFixed(1); // Display one decimal
    }
    return null;
  }, [userLocation, shop.location]);

  const handleShopClick = () => {
    navigate(`/${shop.id}`); // Navigate to the shop's profile page using its ID
  };

  return (
    <div className="shop-card" onClick={handleShopClick}>
      {/* Left section: Image */}
      <img
        src={shop.shopImage || shop.profilePic || logo}
        alt={`${shop.shopName || shop.ownerName} Logo`}
        className="shop-logo"
      />

      {/* Right section: Details */}
      <div className="shop-details">
        {/* First Row: Shop Name, Icons, Distance */}
        <div className="shop-header">
          <h3 className="shop-name">{shop.shopName || shop.ownerName}</h3>
          <div className="shop-icons">
            {shop.delivery && (
              <FaCar className="icon" title="Delivery Available" />
            )}
            {shop.pickup && (
              <FaShoppingBasket className="icon" title="Pickup Available" />
            )}
          </div>
          <span className="shop-distance">
            {distance !== null ? `${distance} KM` : "Loading..."}
          </span>
        </div>

        {/* Second Row: Category */}
        <div className="shop-category">
          {shop.categories ? shop.categories.join(", ") : "No categories"}
        </div>

        {/* Third Row: Timings */}
        <div className="shop-timings">
          {todayTiming.status === "closed"
            ? "Closed"
            : `${todayTiming.open} - ${todayTiming.close}`}
        </div>
      </div>
    </div>
  );
};

export default ShopCard;
