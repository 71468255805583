// UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../../firebase'; // Import Firebase authentication and Firestore
import { doc, getDoc, setDoc } from "firebase/firestore"; // Firestore functions

// Create UserContext
const UserContext = createContext();

// Export custom hook for accessing the context
export const useUser = () => useContext(UserContext);

// Create UserProvider to wrap around the application
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Define an asynchronous updateUser function to update Firestore and context
  const updateUser = async (newUser) => {
    try {
      if (newUser && newUser.uid) {
        // Use setDoc to update Firestore user data
        await setDoc(doc(db, "Users", newUser.uid), newUser, { merge: true });
      }
      setUser(newUser);
      setError(null);
    } catch (err) {
      console.error("Failed to update user:", err);
      setError("Failed to update user data.");
    }
  };

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, "Users", currentUser.uid));
          if (userDoc.exists()) {
            setUser({
              uid: currentUser.uid,
              email: currentUser.email,
              displayName: currentUser.displayName,
              phoneNumber: currentUser.phoneNumber,
              ...userDoc.data(),
            });
          } else {
            setUser(null);
          }
        } catch (err) {
          console.error("Error fetching user data:", err);
          setError("Failed to fetch user data.");
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return unsubscribeAuth;
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, updateUser, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
