import React from 'react';

function Chats() {
  return (
    <div>
      <h3>Chats</h3>
      <p>Manage your chats here.</p>
    </div>
  );
}

export default Chats;
