import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

const Cities = ({ show, onClose, cities }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Select a City</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {cities.length > 0 ? (
          <ul className="list-group">
            {cities.map((city, index) => (
              <li key={index} className="list-group-item">
                {city}
              </li>
            ))}
          </ul>
        ) : (
          <p>No cities available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Cities.propTypes = {
  show: PropTypes.bool.isRequired, // Controls whether the modal is visible
  onClose: PropTypes.func.isRequired, // Function to handle modal close
  cities: PropTypes.arrayOf(PropTypes.string).isRequired, // List of cities
};

export default Cities;
