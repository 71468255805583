// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCd8hmluXWDjXridf9YQawm7Z6GLabqfCI",
  authDomain: "jift-india.firebaseapp.com",
  databaseURL: "https://jift-india-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "jift-india",
  storageBucket: "jift-india.appspot.com",
  messagingSenderId: "466124586349",
  appId: "1:466124586349:web:4d896561a04ae8e8856e64",
  measurementId: "G-HLK0V612W1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore
const auth = getAuth(app); // Initialize Auth


export { app, db, auth, RecaptchaVerifier };
