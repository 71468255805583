import React from 'react';

function LoginTickets() {
  return (
    <div>
      <h3>LoginTickets</h3>
      <p>Manage your LoginTickets here.</p>
    </div>
  );
}

export default LoginTickets;
