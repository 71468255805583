import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { UserProvider } from "../components/Context/UserContext";
import "./css/AboutUs.css";

const AboutUs = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <UserProvider>
      <div className="about-us">
        <Header setSearchTerm={setSearchTerm} />
        <div className="content">
          <div className="main-content">
            <h1>About Us</h1>
            <p><strong>Welcome to Jift!</strong></p>
            
            <section>
              <h2>Our Mission</h2>
              <p>
                At Jift, our mission is to connect people with local shops and services in a way that's convenient, accessible, and reliable. We aim to make shopping a seamless experience by helping users discover the best options right around the corner.
              </p>
            </section>

            <section>
              <h2>Who We Are</h2>
              <p>
                We are a team of passionate individuals dedicated to improving the way people shop. Our team consists of tech enthusiasts, business professionals, and customer service experts who all share a common goal: to provide an exceptional app experience.
              </p>
              <p>
                With years of experience in the industry, we know how important it is for consumers to easily access and find trusted local shops. That’s why we built Jift – an app designed to simplify this process and help users make informed decisions about where to shop.
              </p>
            </section>

            <section>
              <h2>Our Values</h2>
              <ul>
                <li><strong>Innovation:</strong> We embrace innovation and constantly strive to improve our services with the latest technology.</li>
                <li><strong>Customer-Centric:</strong> Our customers are our top priority. We ensure that every feature we build caters to their needs.</li>
                <li><strong>Community:</strong> We believe in the power of community and support local businesses by connecting them with potential customers.</li>
                <li><strong>Integrity:</strong> We conduct our business with the highest ethical standards and provide transparent services.</li>
              </ul>
            </section>

            <section>
              <h2>Why Choose Jift?</h2>
              <p>
                Whether you're looking for a local shop for groceries, electronics, fashion, or even a nearby restaurant, Jift helps you find the best options based on your preferences. We focus on giving you the most accurate and up-to-date information, including shop ratings, reviews, and directions – all from the comfort of your phone.
              </p>
            </section>

            <section>
              <h2>Our Story</h2>
              <p>
                The idea for Jift began with a simple observation: finding the right shop can often be a frustrating and time-consuming experience. Whether you're new to an area or just looking for something specific, discovering the best options was never easy.
              </p>
              <p>
                That's when we decided to create a platform that would make local shopping easier by providing real-time, location-based recommendations. Over time, we’ve built relationships with local business owners and have continually enhanced our app to provide an unparalleled user experience.
              </p>
            </section>
            
            <section>
              <h2>Contact Us</h2>
              <p>
                If you have any questions or want to learn more about us, feel free to reach out! We are always here to help and would love to hear from you.
              </p>
              <p>Email us at: <strong>support@jift.com</strong></p>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </UserProvider>
  );
};

export default AboutUs;
