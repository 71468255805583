import React, { useState } from 'react';
import './css/Modal.css';
import { auth } from '../../firebase';
import { signInWithPhoneNumber } from "firebase/auth";

const Modal = ({ isOpen, onClose, onSendOTP, mobile, setMobile, otp, setOtp, onSubmit, errorMessage }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Login / Sign Up</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {!otp ? (
          <>
            <input
              type="text"
              placeholder="Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            <button id="send-otp-button" onClick={onSendOTP}>Send OTP</button>
          </>
        ) : (
          <>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button onClick={onSubmit}>Login</button>
          </>
        )}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Modal;
