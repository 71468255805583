import React from 'react';

function Ratings() {
  return (
    <div>
      <h3>Ratings</h3>
      <p>Manage your ratings here.</p>
    </div>
  );
}

export default Ratings;
