import React, { useState, useEffect, useContext } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { haversineDistance } from "../utils/haversineDistance";
import "./css/Listing.css";
import ShopCard from "./ShopCard";
import { LocationContext } from "./Context/LocationProvider"; // Import the LocationContext

const Listing = ({ category }) => {
  const { location } = useContext(LocationContext); // Access location from context

  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!location) {
      console.log("Waiting for location data...");
      return; // Wait for location data to update
    }

    const fetchShops = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Shops"));
        let shopList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
    
        // Apply category filtering if category is provided
        if (category) {
          shopList = shopList.filter((shop) => shop.categories.includes(category));
        }
    
        // Calculate distances and add a distance property to each shop
        shopList = shopList.map((shop) => {
          const shopCoords = {
            latitude: shop.location._lat,
            longitude: shop.location._long,
          };
          const distance = haversineDistance(location, shopCoords);
          return { ...shop, distance };
        });
    
        // Sort shops based on proximity
        shopList = shopList.sort((a, b) => a.distance - b.distance);
    
        // Apply the maximum distance filter based on category
        const maxDistance = category ? 3 : 20;
    
        // Filter out shops that are farther than maxDistance
        shopList = shopList.filter((shop) => shop.distance <= maxDistance);
    
        setShops(shopList);
      } catch (error) {
        console.error("Error fetching shops:", error);
        setError("Failed to load shops. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    

    fetchShops();
  }, [location, category]); // Trigger effect when location or category changes

  if (!location) {
    return <p>Fetching your location, please wait...</p>;
  }

  return (
    <div className="content" style={{ paddingTop: "0px" }}>
      {loading ? (
        <p>Loading shops...</p>
      ) : error ? (
        <p>{error}</p>
      ) : shops.length > 0 ? (
        <div className="shop-list">
          {shops.map((shop) => (
            <ShopCard
              key={shop.id}
              shop={shop}
              onClick={() => console.log(`Shop clicked: ${shop.id}`)}
            />
          ))}
        </div>
      ) : (
        <p>No shops found matching your search.</p>
      )}
    </div>
  );
};

export default Listing;
