// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import ShopDetail from './components/ShopDetail';
import Privacy from './screens/privacypolicy';
import Terms from './screens/termscondition';
import AboutUs from './screens/aboutus';
import ContactUs from './screens/contactus';
import Phone from './components/Phone';
import AdminDashboard from './admin/screens/Dashboard/Dashboard'; // Your Admin Dashboard component

const App = () => {
    const hostname = window.location.hostname;
    console.log("Current hostname:", hostname); // Check if hostname is detected correctly

    const isSubdomain = hostname.startsWith("my.");

    return (
        <>
            {isSubdomain ? (
                <Routes>
                    <Route path="/*" element={<Login />} />
                    <Route path="/admin" element={<AdminDashboard />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:id" element={<ShopDetail />} />
                    <Route path="/privacypolicy" element={<Privacy />} />
                    <Route path="/termscondition" element={<Terms />} />
                    <Route path="/aboutus" element={<AboutUs />} />
                    <Route path="/contactus" element={<ContactUs />} />
                    <Route path="/phone" element={<Phone />} />
                </Routes>
            )}
        </>
    );
};

export default App;
