import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/Header.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from "../images/logo.png";
import downloadAppImg from "../images/Download.svg";
import Cities from "./Models/Citis"; // Assuming you have a modal component
import country from "../js/countries"; // Make sure this is correctly imported
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Ensure the correct path to your Firebase configuration file
import categories from "../js/categories";
import { useNavigate } from "react-router-dom"; // Import navigation hook
import { LocationContext } from "./Context/LocationProvider";

const Header = () => {
  const { location, setLocation } = useContext(LocationContext); // Access global location state
  const [userLocation, setUserLocation] = useState("Fetching your location...");
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [cities, setCities] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Initialize navigation
  const navigate = useNavigate(); 

  // Ref for suggestion box
  const suggestionBoxRef = useRef(null);

  useEffect(() => {
    if (location?.city && location?.country) {
      setUserLocation(location.city);
      setCities(country[location.country]?.cities || []);
    } else {
      getCurrentLocation();
    }
  }, [location]);

  const fetchCityName = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      );
      const data = await response.json();
      if (data && data.city && data.countryName) {
        const newLocation = {
          city: data.city,
          country: data.countryName,
          latitude,
          longitude,
        };
        setLocation(newLocation); // Update context state
        setUserLocation(data.city);
        setCountryName(data.countryName); // Keep track of country
        setCities(country[data.countryName]?.cities || []); // Populate cities
      } else {
        throw new Error("City or Country data missing from response");
      }
    } catch (error) {
      console.error("Error fetching city name:", error);
      setUserLocation("Unable to fetch location");
    }
  };
  

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        fetchCityName(latitude, longitude);
        setPermissionStatus("granted");
      },
      (error) => {
        if (error.code === 1) {
          setPermissionStatus("denied");
          setUserLocation("Location access denied. Click the icon to try again.");
        } else {
          setUserLocation("Unable to fetch location");
        }
      }
    );
  };

  const checkPermissionStatus = async () => {
    if ("permissions" in navigator) {
      try {
        const status = await navigator.permissions.query({
          name: "geolocation",
        });
        setPermissionStatus(status.state);
        status.onchange = () => setPermissionStatus(status.state); // Listen for permission changes
      } catch (error) {
        console.error("Error checking permission status:", error);
      }
    }
  };

  useEffect(() => {
    checkPermissionStatus();
    getCurrentLocation();

  }, []);

  useEffect(() => {
    if (countryName && country[countryName]) {
      setCities(country[countryName].cities);
      console.log(userLocation);
      console.log(countryName);
    }
  }, [countryName]);

  const handleLocationClick = () => {
    if (permissionStatus === "denied") {
      alert(
        "Location permission is denied. Please enable it in your browser settings and try again."
      );
      return;
    }
    setShowModal(true); // Open modal for city selection
  };

  const fetchShopSuggestions = async (input) => {
    if (input.length < 3) {
      setSuggestions([]);
      return;
    }
  
    try {
      const normalizedInput = input.toLowerCase();
      const shopsRef = collection(db, "Shops");
  
      // Query Firestore for shops that match the input and are in the same city and country
      const q = query(
        shopsRef,
        where("city", "==", userLocation), // Filter by city
        where("country.name", "==", countryName) // Filter by country
      );
  
      const querySnapshot = await getDocs(q);
  
      const shopResults = [];
      const categoryResults = new Set();
  
      // Iterate through the results
      querySnapshot.forEach((doc) => {
        const data = doc.data();
  
        const shopName = data.shopName ? data.shopName.toLowerCase() : "";
        const ownerName = data.ownerName ? data.ownerName.toLowerCase() : "";
  
        if (
          shopName.includes(normalizedInput) ||
          ownerName.includes(normalizedInput)
        ) {
          shopResults.push({ id: doc.id, ...data });
        }
      });
  
      // Use categories.js for category suggestions
      for (const categoryGroup in categories) {
        const groupCategories = categories[categoryGroup];
        groupCategories.forEach((item) => {
          if (item.label.toLowerCase().includes(normalizedInput)) {
            categoryResults.add(item.label);
          }
        });
      }
  
      // Set suggestions for the UI
      setSuggestions({
        shops: shopResults,
        categories: Array.from(categoryResults),
      });
    } catch (error) {
      console.error("Error fetching shop suggestions:", error);
    }
  };
  
  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    fetchShopSuggestions(input);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionBoxRef.current &&
      !suggestionBoxRef.current.contains(event.target)
    ) {
      setSuggestions([]); // Hide suggestions if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleShopClick = (shop) => {
    navigate(`/${shop.id}`); // Navigate to shop profile page with shop ID
  };

  return (
    <>
      <header>

        <a className="logo" href="/">
          <img src={logo} alt="Jift Logo" />
        </a>

        <div className="user-location" onClick={handleLocationClick}>
          <i className="fas fa-map-marker-alt clickable-icon"></i>
          <p>{userLocation}</p>
        </div>

        <div className="search-bar-head">
          <input
            type="text"
            placeholder="Search here..."
            value={searchInput}
            onChange={handleSearchChange}
          />
          <button className="search-button">
            <i className="fas fa-search"></i>
          </button>

          {suggestions.shops?.length > 0 ||
          suggestions.categories?.length > 0 ? (
            <div className="auto-suggestions" ref={suggestionBoxRef}>
              {suggestions.categories?.length > 0 && (
                <div className="suggestion-section">
                  <h5>in categories</h5>
                  <ul>
                    {suggestions.categories.map((category, index) => (
                      <li
                        key={`category-${index}`}
                        onClick={() => {
                          setSearchInput(category);
                          setSuggestions({ shops: [], categories: [] });
                        }}
                      >
                        {category}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {suggestions.shops?.length > 0 && (
                <div className="suggestion-section">
                  <h5>in shops</h5>
                  <ul>
                    {suggestions.shops.map((shop) => (
                      <li
                        key={shop.id}
                        onClick={() => {
                          setSearchInput(shop.shopName || shop.ownerName);
                          handleShopClick(shop); // Navigate to shop profile
                          setSuggestions({ shops: [], categories: [] });
                        }}
                      >
                        {shop.shopName || shop.ownerName}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : null}
        </div>

        <div className="header-links">
          <a href="/download-app">
            <img
              src={downloadAppImg}
              alt="Download App"
              className="download-app-img"
            />
          </a>
          <a href="/list-now" className="bold-text">
            Free Listing
          </a>
          <a href="/customer-care">
            <i className="fas fa-headset"></i>
            Customer Care
          </a>
          <button href="/login-signup" className="btn-login-signup">
            Login/Signup
          </button>
        </div>
      </header>

      {showModal && (
        <Cities
          show={showModal}
          onClose={() => setShowModal(false)}
          cities={cities}
        />
      )}
    </>
  );
};

export default Header;
