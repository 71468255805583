import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import Users from '../components/Users'; // Ensure 'components' matches actual folder name
import Shops from '../components/Shops';
import Chats from '../components/Chats';
import Tickets from '../components/Tickets';
import Promotions from '../components/Promotions';
import Favorites from '../components/Favorites';
import Ratings from '../components/Ratings';
import Settings from '../components/Settings';
import LoginTickets from '../components/tickets/LoginTickets';
import PromoTickets from '../components/tickets/PromoTickets';
import SettingTickets from '../components/tickets/SettingTickets';
import ShopSetupTickets from '../components/tickets/ShopSetupTickets';

const SECTIONS = ['Users', 'Shops', 'Chats', 'Tickets', 'Promotions', 'Favorites', 'Ratings', 'Settings'];

const TICKET_SUBSECTIONS = ['Login', 'Promo', 'Setting', 'ShopSetup'];

function Dashboard() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [isHovered, setIsHovered] = useState(false);
  const [activeSection, setActiveSection] = useState('Users');
  const [activeSubSection, setActiveSubSection] = useState(null); // For managing submenus
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userRoleDoc = await getDoc(doc(db, 'userRoles', user.uid));
          if (userRoleDoc.exists() && userRoleDoc.data().role === 'admin') {
            setIsAdmin(true);
          } else {
            navigate('/unauthorized');
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
          navigate('/login');
        } finally {
          setIsLoading(false);
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (err) {
      console.error("Logout error: ", err);
    }
  };

  const renderActiveSection = () => {
    if (activeSection === 'Tickets') {
      switch (activeSubSection) {
        case 'Login':
          return <LoginTickets />;
        case 'Promo':
          return <PromoTickets />;
        case 'Setting':
          return <SettingTickets />;
        case 'ShopSetup':
          return <ShopSetupTickets />;
        default:
          return <Tickets />;
      }
    }
    switch (activeSection) {
      case 'Users': return <Users />;
      case 'Shops': return <Shops />;
      case 'Chats': return <Chats />;
      case 'Promotions': return <Promotions />;
      case 'Favorites': return <Favorites />;
      case 'Ratings': return <Ratings />;
      case 'Settings': return <Settings />;
      default: return <Users />;
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (!isAdmin) return null;

  return (
    <div style={styles.container}>
      {/* Sidebar */}
      <aside style={styles.sidebar}>
        <div style={styles.logo}>
          <h1 style={styles.logoText}>Admin</h1>
        </div>
        <nav>
  <ul style={styles.navList}>
    {SECTIONS.map((item, index) => (
      <li key={index} style={styles.navItem}>
        <a
          href="#"
          style={{
            ...styles.navLink,
            backgroundColor: activeSection === item ? '#2980b9' : '#34495e',
          }}
          onClick={() => {
            setActiveSection(item);
            setActiveSubSection(null); // Reset submenu when switching sections
          }}
        >
          {item}
        </a>
        {/* Submenu for Tickets */}
        {item === 'Tickets' && activeSection === 'Tickets' && (
          <ul style={styles.subMenu}>
            {TICKET_SUBSECTIONS.map((subItem, subIndex) => (
              <li key={subIndex} style={styles.subNavItem}>
                <a
                  href="#"
                  style={{
                    ...styles.navLink,
                    backgroundColor: activeSubSection === subItem ? '#1abc9c' : '#34495e',
                  }}
                  onClick={() => setActiveSubSection(subItem)}
                >
                  {subItem}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
</nav>

        <button
          style={{
            ...styles.logoutButton,
            backgroundColor: isHovered ? '#d9534f' : '#f0ad4e',
          }}
          onClick={handleLogout}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Logout
        </button>
      </aside>

      {/* Main Content */}
      <main style={styles.mainContent}>
        <section style={styles.content}>
          {renderActiveSection()}
        </section>
      </main>
    </div>
  );
}

export default Dashboard;

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    fontFamily: '"Roboto", sans-serif',
  },
  sidebar: {
    width: '250px',
    backgroundColor: '#2c3e50',
    color: '#ecf0f1',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    position: 'fixed',
    height: '100vh',
    overflowY: 'auto',
  },
  logo: {
    marginBottom: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    fontSize: '28px',
    fontWeight: '700',
    color: '#ecf0f1',
    margin: 0,
  },
  navList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    display: 'block',
  },
  navItem: {
    marginBottom: '15px',
  },
  subMenu: {
    listStyleType: 'none',
    padding: '10px',
    margin: '10px 0 0 20px', // Indent submenus
  },
  subNavItem: {
    marginBottom: '10px',
  },
  navLink: {
    color: '#ecf0f1',
    textDecoration: 'none',
    fontSize: '16px',
    padding: '10px 0',
    display: 'block',
    borderRadius: '4px',
    textAlign: 'center',
    transition: 'background 0.3s',
    backgroundColor: '#34495e',
  },
  logoutButton: {
    padding: '12px 0',
    fontSize: '16px',
    color: '#ecf0f1',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: 'auto',
    width: '100%',
    transition: 'background-color 0.3s',
  },
  mainContent: {
    marginLeft: '250px',
    padding: '40px',
    width: '100%',
    backgroundColor: '#ecf0f1',
  },
  content: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  subMenu: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    marginLeft: '15px', // Indent submenus slightly to differentiate them
    display: 'block', // Ensure vertical stacking
  },
  subNavItem: {
    marginBottom: '10px', // Add spacing between submenu items
  },
  navLink: {
    color: '#ecf0f1',
    textDecoration: 'none',
    fontSize: '16px',
    padding: '10px 15px', // Add padding for submenu items
    display: 'block', // Ensure full clickable area
    borderRadius: '4px',
    textAlign: 'left', // Align text to the left for submenus
    transition: 'background 0.3s',
    backgroundColor: '#34495e', // Default background color
  },
  
};
