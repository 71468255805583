import React, { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ThinMenu from "./components/ThinMenu";
import Badge from "./components/Badge";
import { UserProvider } from "./components/Context/UserContext";
import Listing from "./components/Listing";

import "./css/Home.css";

const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState(null); // State for selected category

  const handleSubCategoryClick = (category) => {
    console.log("Selected Category:", category); // Debugging
    setSelectedCategory(category); // Update selected category
  };

  return (
    <UserProvider>
      <div className="home">
        {/* Pass setLocation as a prop to Header */}
        <Header />
        <ThinMenu onSubCategoryClick={handleSubCategoryClick}/>
        <Badge
          category={selectedCategory}
          onClearCategory={() => setSelectedCategory(null)} // Reset category on close
        />

        <Listing category={selectedCategory}/>
        <Footer />
      </div>
    </UserProvider>
  );
};

export default Home;
