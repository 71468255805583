import * as FaIcons from "react-icons/fa";

const categories = {
    "Home Services": [
      {
        label: "Carpenter",
        description: "Carpenter",
        full_description:
          "Skilled craftsman specializing in woodwork, furniture making, and repairs.",
        icon: <FaIcons.FaHammer />,
      },
      {
        label: "Curtain/Blind Installation",
        description: "Curtain/Blind Installation",
        full_description:
          "Professional installation of curtains and blinds to enhance home decor.",
        icon: <FaIcons.FaWindowMaximize />,
      },
      {
        label: "Flooring Specialist",
        description: "Flooring Specialist",
        full_description:
          "Expert in installing and repairing various types of flooring, including hardwood, tile, and carpet.",
        icon: <FaIcons.FaThLarge />,
      },
      {
        label: "Gardener",
        description: "Gardener",
        full_description:
          "Provides services for planting, maintaining, and caring for gardens and landscapes.",
        icon: <FaIcons.FaSeedling />,
      },
      {
        label: "House Cleaning",
        description: "House Cleaning",
        full_description:
          "Thorough cleaning services for residential properties, including deep cleaning and regular maintenance.",
        icon: <FaIcons.FaBroom />,
      },
      {
        label: "Painter",
        description: "Painter",
        full_description:
          "Professional painting services for both interior and exterior surfaces of homes.",
        icon: <FaIcons.FaPaintRoller />,
      },
      {
        label: "Plumber",
        description: "Plumber",
        full_description:
          "Expertise in installing, repairing, and maintaining plumbing systems in residential properties.",
        icon: <FaIcons.FaFaucet />,
      },
      {
        label: "Roofer",
        description: "Roofer",
        full_description:
          "Specialist in roofing services, including installation, repair, and maintenance of roofs.",
        icon: <FaIcons.FaHome />,
      },
      {
        label: "Sanitary Services",
        description: "Sanitary Services",
        full_description:
          "Services that ensure the sanitation and hygiene of homes, including waste disposal.",
        icon: <FaIcons.FaToilet />,
      },
      {
        label: "Sofa/Carpet Cleaning",
        description: "Sofa/Carpet Cleaning",
        full_description:
          "Professional cleaning services for sofas, carpets, and other upholstery.",
        icon: <FaIcons.FaCouch />,
      },
      {
        label: "Window Cleaning",
        description: "Window Cleaning",
        full_description:
          "Cleaning services for residential windows, ensuring they are streak-free and clear.",
        icon: <FaIcons.FaWindows />,
      },
      {
        label: "Electrician",
        description: "Electrician",
        full_description:
          "Certified professionals providing electrical installations, repairs, and maintenance.",
        icon: <FaIcons.FaBolt />,
      },
      {
        label: "HVAC Technician",
        description: "HVAC Technician",
        full_description:
          "Specialist in the installation and repair of heating and cooling systems.",
        icon: <FaIcons.FaFan />,
      },
      {
        label: "Pest Control",
        description: "Pest Control",
        full_description:
          "Services to eliminate and prevent pests in residential properties.",
        icon: <FaIcons.FaBug />,
      },
      {
        label: "Moving Services",
        description: "Moving Services",
        full_description:
          "Professional moving services for residential and commercial relocations.",
        icon: <FaIcons.FaTruck />,
      },
      {
        label: "Handyman Services",
        description: "Handyman Services",
        full_description:
          "Versatile service provider for various home repair and maintenance tasks.",
        icon: <FaIcons.FaTools />,
      },
      {
        label: "Pressure Washing",
        description: "Pressure Washing",
        full_description:
          "High-pressure cleaning services for exterior surfaces, including driveways and patios.",
        icon: <FaIcons.FaWater />,
      },
      {
        label: "Landscaping Services",
        description: "Landscaping Services",
        full_description:
          "Services for designing and maintaining outdoor spaces and gardens.",
        icon: <FaIcons.FaTree />,
      },
      {
        label: "Gutter Cleaning",
        description: "Gutter Cleaning",
        full_description:
          "Cleaning and maintenance services for residential gutters to ensure proper drainage.",
        icon: <FaIcons.FaWater />,
      },
      {
        label: "Home Security Installation",
        description: "Home Security Installation",
        full_description:
          "Installation of security systems and devices to protect residential properties.",
        icon: <FaIcons.FaLock />,
      },
      {
        label: "Interior Designer",
        description: "Interior Designer",
        full_description:
          "Professional design services to enhance the interior aesthetics and functionality of homes.",
        icon: <FaIcons.FaDraftingCompass />,
      },
    ],
    "Personal Care": [
      {
        label: "Babysitter/Nanny",
        description: "Babysitter/Nanny",
        full_description:
          "Provides care and supervision for children in the absence of parents.",
        icon: <FaIcons.FaChild />,
      },
      {
        label: "Beauty Parlor (Women)",
        description: "Beauty Parlor (Women)",
        full_description:
          "Beauty services specifically for women, including hair, makeup, and skincare treatments.",
        icon: <FaIcons.FaFemale />,
      },
      {
        label: "Dog Walker/Pet Care",
        description: "Dog Walker/Pet Care",
        full_description:
          "Services to walk and care for pets, ensuring their exercise and well-being.",
        icon: <FaIcons.FaPaw />,
      },
      {
        label: "Driver",
        description: "Driver",
        full_description:
          "Professional driving services for personal or commercial transportation needs.",
        icon: <FaIcons.FaTaxi />,
      },
      {
        label: "Hair Salon (Men)",
        description: "Hair Salon (Men)",
        full_description:
          "Haircut and grooming services specifically tailored for men.",
        icon: <FaIcons.FaMale />,
      },
      {
        label: "Tailor",
        description: "Tailor",
        full_description:
          "Expert in sewing, alterations, and custom clothing design.",
        icon: <FaIcons.FaTshirt />,
      },
      {
        label: "Massage Therapist",
        description: "Massage Therapist",
        full_description:
          "Professional massage services for relaxation and therapeutic purposes.",
        icon: <FaIcons.FaHands />,
      },
      {
        label: "Personal Trainer",
        description: "Personal Trainer",
        full_description:
          "Personalized fitness training sessions to achieve health and fitness goals.",
        icon: <FaIcons.FaDumbbell />,
      },
      {
        label: "Makeup Artist",
        description: "Makeup Artist",
        full_description:
          "Makeup application services for events, photoshoots, and personal styling.",
        icon: <FaIcons.FaPaintBrush />,
      },
      {
        label: "Spa Services",
        description: "Spa Services",
        full_description:
          "Spa services including facials, body treatments, and relaxation therapies.",
        icon: <FaIcons.FaSpa />,
      },
      {
        label: "Nail Technician",
        description: "Nail Technician",
        full_description:
          "Professional services for manicures, pedicures, and nail art.",
        icon: <FaIcons.FaSpa />, //test
      },
      {
        label: "Chiropractor",
        description: "Chiropractor",
        full_description:
          "Specialist in diagnosing and treating musculoskeletal disorders through manual adjustments.",
        icon: <FaIcons.FaStethoscope />,
      },
    ],
    "Health Care": [
      {
        label: "General Practitioner (Doctor)",
        description: "General Practitioner (Doctor)",
        full_description:
          "A medical doctor who provides comprehensive healthcare to patients of all ages.",
        icon: <FaIcons.FaUserMd />,
      },
      {
        label: "Nurse",
        description: "Nurse",
        full_description:
          "A healthcare professional responsible for patient care, education, and support.",
        icon: <FaIcons.FaHeartbeat />,
      },
      {
        label: "Physical Therapist",
        description: "Physical Therapist",
        full_description:
          "A specialist who helps patients improve their movement and manage pain through physical rehabilitation.",
        icon: <FaIcons.FaRunning />,
      },
      {
        label: "Occupational Therapist",
        description: "Occupational Therapist",
        full_description:
          "Therapist who assists patients in developing, recovering, and improving the skills needed for daily living.",
        icon: <FaIcons.FaHandPaper />,
      },
      {
        label: "Home Health Aide",
        description: "Home Health Aide",
        full_description:
          "A professional providing personal care and assistance to patients in their homes.",
        icon: <FaIcons.FaHome />,
      },
      {
        label: "Personal Care Assistant",
        description: "Personal Care Assistant",
        full_description:
          "Provides support and assistance to individuals in daily living activities.",
        icon: <FaIcons.FaHandsHelping />,
      },
      {
        label: "Nutritionist/Dietitian",
        description: "Nutritionist/Dietitian",
        full_description:
          "An expert in food and nutrition, providing advice to help individuals maintain a healthy diet.",
        icon: <FaIcons.FaAppleAlt />,
      },
      {
        label: "Mental Health Counselor",
        description: "Mental Health Counselor",
        full_description:
          "A professional who provides therapy and support for mental health issues.",
        icon: <FaIcons.FaBrain />,
      },
      {
        label: "Chiropractor",
        description: "Chiropractor",
        full_description:
          "A healthcare professional who specializes in diagnosing and treating musculoskeletal disorders.",
        icon: <FaIcons.FaStethoscope />,
      },
      {
        label: "Massage Therapist",
        description: "Massage Therapist",
        full_description:
          "A professional who provides therapeutic massage services for relaxation and rehabilitation.",
        icon: <FaIcons.FaHands />,
      },
      {
        label: "Dentist",
        description: "Dentist",
        full_description:
          "A healthcare professional who specializes in oral health and dental care.",
        icon: <FaIcons.FaTooth />,
      },
      {
        label: "Optometrist",
        description: "Optometrist",
        full_description:
          "A specialist in eye care, providing vision examinations and prescribing corrective lenses.",
        // icon: <FaIcons.FaEyedropper />, //test
      },
      {
        label: "Pediatrician",
        description: "Pediatrician",
        full_description:
          "A medical doctor specializing in the care of infants, children, and adolescents.",
        icon: <FaIcons.FaChild />,
      },
      {
        label: "Elder Care Specialist",
        description: "Elder Care Specialist",
        full_description:
          "A professional providing care and support for the elderly, addressing their specific needs.",
        icon: <FaIcons.FaUserShield />,
      },
      {
        label: "Midwife/Doula",
        description: "Midwife/Doula",
        full_description:
          "A trained professional who provides support and care during pregnancy, childbirth, and postpartum.",
        // icon: <FaIcons.FaPregnantWoman />, //test
      },
      {
        label: "Speech Therapist",
        description: "Speech Therapist",
        full_description:
          "A specialist who assesses and treats speech, language, and communication disorders.",
        icon: <FaIcons.FaVolumeUp />,
      },
      {
        label: "Pharmacist Consultation",
        description: "Pharmacist Consultation",
        full_description:
          "Consultation services provided by a pharmacist regarding medications and health.",
        icon: <FaIcons.FaPills />,
      },
      {
        label: "Laboratory Testing Service",
        description: "Laboratory Testing Service",
        full_description:
          "Services providing diagnostic laboratory tests and analysis for healthcare providers.",
        icon: <FaIcons.FaFlask />,
      },
      {
        label: "Vaccination Service",
        description: "Vaccination Service",
        full_description:
          "Services providing vaccinations for various diseases to ensure public health.",
        icon: <FaIcons.FaSyringe />,
      },
      {
        label: "Medical Equipment Rental",
        description: "Medical Equipment Rental",
        full_description:
          "Rental services for medical equipment needed for patient care at home.",
        icon: <FaIcons.FaShoppingBasket />,
      },
      {
        label: "Homeopathic Practitioner",
        description: "Homeopathic Practitioner",
        full_description:
          "A professional who practices homeopathy, treating patients with natural remedies.",
        icon: <FaIcons.FaLeaf />,
      },
      {
        label: "Ayurvedic Clinic",
        description: "Ayurvedic Clinic",
        full_description:
          "A clinic specializing in Ayurvedic medicine, promoting holistic health through natural methods.",
        icon: <FaIcons.FaSpa />,
      },
    ],
    "Medical Shops": [
      {
        label: "Pharmacy",
        description: "Pharmacy",
        full_description:
          "A retail establishment where medications and health products are dispensed.",
        icon: <FaIcons.FaPrescriptionBottleAlt />,
      },
      {
        label: "Medical Equipment Store",
        description: "Medical Equipment Store",
        full_description:
          "A store specializing in the sale of medical equipment for home and clinical use.",
        icon: <FaIcons.FaShoppingCart />,
      },
      {
        label: "Over-the-Counter Medication Store",
        description: "Over-the-Counter Medication Store",
        full_description:
          "A store offering non-prescription medications and health products.",
        icon: <FaIcons.FaPills />,
      },
      {
        label: "Homeopathic Medicine Shop",
        description: "Homeopathic Medicine Shop",
        full_description:
          "A store specializing in homeopathic remedies and natural health products.",
        icon: <FaIcons.FaLeaf />,
      },
      {
        label: "Ayurvedic Medicine Store",
        description: "Ayurvedic Medicine Store",
        full_description:
          "A store offering Ayurvedic medicines and herbal health products.",
        icon: <FaIcons.FaHandSparkles />,
      },
      {
        label: "Health and Wellness Supplements",
        description: "Health and Wellness Supplements",
        full_description:
          "A store providing nutritional supplements for health and wellness.",
        // icon: <FaIcons.FaVitamin />, //test
      },
      {
        label: "First Aid Supplies",
        description: "First Aid Supplies",
        full_description:
          "A shop offering supplies and kits for basic first aid needs.",
        icon: <FaIcons.FaPlusSquare />,
      },
      {
        label: "Surgical Supplies",
        description: "Surgical Supplies",
        full_description:
          "A store providing surgical instruments and supplies for medical procedures.",
        // icon: <FaIcons.FaScalpel />, //test
      },
      {
        label: "Prescription Medication Delivery",
        description: "Prescription Medication Delivery",
        full_description:
          "Services delivering prescribed medications to patients at home.",
        icon: <FaIcons.FaTruckLoading />,
      },
      {
        label: "Medical Supplies for Elder Care",
        description: "Medical Supplies for Elder Care",
        full_description:
          "Supplies and equipment specifically for the care of elderly individuals.",
        icon: <FaIcons.FaUserShield />,
      },
      {
        label: "Medical Cosmetics and Skin Care",
        description: "Medical Cosmetics and Skin Care",
        full_description:
          "Products and cosmetics designed for medical and skin health.",
        icon: <FaIcons.FaSpa />,
      },
      {
        label: "Diagnostic Equipment Sales",
        description: "Diagnostic Equipment Sales",
        full_description:
          "Sales of equipment used for diagnostic purposes in healthcare.",
        icon: <FaIcons.FaMicroscope />,
      },
      {
        label: "Orthopedic Supplies",
        description: "Orthopedic Supplies",
        full_description:
          "Products and equipment designed for orthopedic care and support.",
        icon: <FaIcons.FaBone />,
      },
      {
        label: "Respiratory Care Supplies (e.g., CPAP/BIPAP)",
        description: "Respiratory Care Supplies (e.g., CPAP/BIPAP)",
        full_description:
          "Supplies for respiratory care, including devices for sleep apnea.",
        icon: <FaIcons.FaLungs />,
      },
      {
        label: "Diabetic Care Supplies",
        description: "Diabetic Care Supplies",
        full_description:
          "Supplies and products for the management of diabetes.",
        icon: <FaIcons.FaHeartbeat />,
      },
      {
        label: "Maternity and Baby Care Supplies",
        description: "Maternity and Baby Care Supplies",
        full_description:
          "Products designed for maternal and infant health care.",
        icon: <FaIcons.FaBaby />,
      },
      {
        label: "Vaccination Supplies",
        description: "Vaccination Supplies",
        full_description: "Supplies needed for administering vaccines.",
        icon: <FaIcons.FaSyringe />,
      },
      {
        label: "Sanitary and Hygiene Products",
        description: "Sanitary and Hygiene Products",
        full_description: "Products promoting personal hygiene and sanitation.",
        icon: <FaIcons.FaSoap />,
      },
    ],
    "Automobile Services": [
      {
        label: "Bike Mechanic",
        description: "Bike Mechanic",
        full_description:
          "A professional who repairs and maintains motorcycles and scooters.",
        icon: <FaIcons.FaMotorcycle />,
      },
      {
        label: "Car Mechanic",
        description: "Car Mechanic",
        full_description:
          "An expert in diagnosing and fixing issues in automobiles.",
        // icon: <FaIcons.FaCarRepair />, //test
      },
      {
        label: "Driver Services",
        description: "Driver Services",
        full_description:
          "Services offering professional drivers for personal or commercial use.",
        icon: <FaIcons.FaTaxi />,
      },
      {
        label: "Car Wash",
        description: "Car Wash",
        full_description:
          "Services providing cleaning and detailing for vehicles.",
        // icon: <FaIcons.FaCarWash />, //test
      },
      {
        label: "Tire Repair",
        description: "Tire Repair",
        full_description:
          "Services to fix flat tires and perform maintenance on vehicle tires.",
        // icon: <FaIcons.FaTire />, //test
      },
      {
        label: "Windshield Replacement",
        description: "Windshield Replacement",
        full_description:
          "Services that replace damaged or broken windshields on vehicles.",
        icon: <FaIcons.FaWindowRestore />,
      },
    ],
    "Electronics Repair": [
      {
        label: "AC Repair",
        description: "AC Repair",
        full_description:
          "Services for diagnosing and fixing issues with air conditioning units.",
        icon: <FaIcons.FaSnowflake />,
      },
      {
        label: "Electrician",
        description: "Electrician",
        full_description:
          "A professional who installs and repairs electrical systems and appliances.",
        icon: <FaIcons.FaPlug />,
      },
      {
        label: "Fridge Repair",
        description: "Fridge Repair",
        full_description:
          "Services for diagnosing and fixing refrigerators and cooling systems.",
        icon: <FaIcons.FaFan />,
      },
      {
        label: "Geyser Repair",
        description: "Geyser Repair",
        full_description: "Repair services for water heaters and geysers.",
        icon: <FaIcons.FaWater />,
      },
      {
        label: "IT Support (Computer/Phone Repair)",
        description: "IT Support (Computer/Phone Repair)",
        full_description:
          "Technical support services for computers and mobile devices.",
        icon: <FaIcons.FaLaptop />,
      },
      {
        label: "TV Repair",
        description: "TV Repair",
        full_description:
          "Services for diagnosing and repairing television sets.",
        icon: <FaIcons.FaTv />,
      },
      {
        label: "Washing Machine Repair",
        description: "Washing Machine Repair",
        full_description:
          "Services for fixing washing machines and laundry appliances.",
        icon: <FaIcons.FaTint />,
      },
      {
        label: "Water Purifier Service",
        description: "Water Purifier Service",
        full_description:
          "Maintenance and repair services for water purification systems.",
        icon: <FaIcons.FaWater />,
      },
      {
        label: "Mobile Phone Repair",
        description: "Mobile Phone Repair",
        full_description:
          "Services for diagnosing and fixing issues with mobile phones.",
        icon: <FaIcons.FaMobileAlt />,
      },
      {
        label: "Solar Panel Installation/Repair",
        description: "Solar Panel Installation/Repair",
        full_description:
          "Services for installing and repairing solar energy systems.",
        icon: <FaIcons.FaSun />,
      },
      
      {
        label: "Laptop Repair",
        description: "Laptop Repair",
        full_description:
          "Services for diagnosing and repairing laptop computers.",
        icon: <FaIcons.FaLaptop />,
      },
    ],
    "Food & Groceries": [
      {
        label: "Dairy",
        description: "Dairy",
        full_description:
          "Products made from milk, including cheese, yogurt, and butter.",
        icon: <FaIcons.FaCheese />,
      },
      {
        label: "Fruits",
        description: "Fruits",
        full_description:
          "Fresh or processed fruits, including apples, bananas, and berries.",
        icon: <FaIcons.FaAppleAlt />,
      },
      {
        label: "Grocery",
        description: "Grocery",
        full_description:
          "A variety of food items available for purchase in stores.",
        icon: <FaIcons.FaShoppingBasket />,
      },
      {
        label: "Sweets",
        description: "Sweets",
        full_description:
          "Confectioneries and desserts, including candies and pastries.",
        icon: <FaIcons.FaCandyCane />,
      },
      {
        label: "Vegetables",
        description: "Vegetables",
        full_description:
          "Fresh or processed vegetables, including leafy greens and root vegetables.",
        icon: <FaIcons.FaCarrot />,
      },
      {
        label: "Beverages",
        description: "Beverages",
        full_description:
          "Drinks including water, juices, sodas, and alcoholic beverages.",
        icon: <FaIcons.FaGlassCheers />,
      },
      {
        label: "Snacks",
        description: "Snacks",
        full_description:
          "Light food items for eating between meals, such as chips and nuts.",
        icon: <FaIcons.FaCookieBite />,
      },
      {
        label: "Organic Products",
        description: "Organic Products",
        full_description:
          "Food products made from organic farming methods without synthetic chemicals.",
        icon: <FaIcons.FaSeedling />,
      },
      {
        label: "Frozen Foods",
        description: "Frozen Foods",
        full_description:
          "Food items preserved by freezing for long-term storage.",
        icon: <FaIcons.FaSnowflake />,
      },
      {
        label: "Meat & Poultry",
        description: "Meat & Poultry",
        full_description:
          "Fresh or processed meat products, including chicken, beef, and pork.",
        icon: <FaIcons.FaDrumstickBite />,
      },
    ],
    "Other Services": [
      {
        label: "Daily Wages",
        description: "Daily Wages",
        full_description:
          "Wages paid for daily work, often in labor or service industries.",
        icon: <FaIcons.FaRegMoneyBillAlt />,
      },
      {
        label: "Home Tutor",
        description: "Home Tutor",
        full_description:
          "Private tutoring services provided in a student's home.",
        icon: <FaIcons.FaChalkboardTeacher />,
      },
      {
        label: "Laundry Service",
        description: "Laundry Service",
        full_description: "Services for washing, drying, and folding clothes.",
        icon: <FaIcons.FaTshirt />,
      },
      {
        label: "Locksmith",
        description: "Locksmith",
        full_description:
          "Professionals who install, repair, and unlock locks.",
        icon: <FaIcons.FaLock />,
      },
      {
        label: "Medical",
        description: "Medical",
        full_description: "Various healthcare services and consultations.",
        icon: <FaIcons.FaHospitalAlt />,
      },
      {
        label: "Movers/Packers",
        description: "Movers/Packers",
        full_description:
          "Services for helping individuals and businesses relocate.",
        icon: <FaIcons.FaTruckMoving />,
      },
      {
        label: "Pest Control",
        description: "Pest Control",
        full_description:
          "Services for managing and eliminating pests in homes and businesses.",
        icon: <FaIcons.FaSpider />,
      },
      {
        label: "Security Guard",
        description: "Security Guard",
        full_description:
          "Professionals who provide security services and protection.",
        icon: <FaIcons.FaShieldAlt />,
      },
      {
        label: "Stationery",
        description: "Stationery",
        full_description:
          "Office and school supplies such as paper, pens, and notebooks.",
        icon: <FaIcons.FaPenAlt />,
      },
      {
        label: "Swimming Pool Maintenance",
        description: "Swimming Pool Maintenance",
        full_description:
          "Services for cleaning and maintaining swimming pools.",
        icon: <FaIcons.FaSwimmingPool />,
      },
      {
        label: "Welder",
        description: "Welder",
        full_description:
          "Professionals who join metal parts together using heat.",
        icon: <FaIcons.FaWrench />,
      },
      {
        label: "Event Planning",
        description: "Event Planning",
        full_description: "Services for organizing and coordinating events.",
        icon: <FaIcons.FaCalendarAlt />,
      },
      {
        label: "Photography Services",
        description: "Photography Services",
        full_description:
          "Professional photography services for events and portraits.",
        icon: <FaIcons.FaCamera />,
      },
      {
        label: "Legal Consultation",
        description: "Legal Consultation",
        full_description:
          "Professional advice provided by a licensed attorney.",
        icon: <FaIcons.FaGavel />,
      },
      {
        label: "Financial Advisor",
        description: "Financial Advisor",
        full_description:
          "Experts who provide financial planning and investment advice.",
        icon: <FaIcons.FaRegMoneyBillAlt />,
      },
      {
        label: "Personal Shopper",
        description: "Personal Shopper",
        full_description:
          "Services that assist individuals in shopping for goods.",
        icon: <FaIcons.FaShoppingCart />,
      },
    ],
  };

  export default categories;
