import React, { useState } from "react";
import "./css/Header.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from "../images/logo.png";
import { signOut, signInWithPhoneNumber } from "firebase/auth";
import { auth, RecaptchaVerifier } from "../firebase";
import { useAuth } from "./Context/AuthContext";
import Modal from "./Models/Modal";

const Header = ({ setSearchTerm }) => {
  const { currentUser } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const handleSendOTP = async () => {
    if (!/^\d{10}$/.test(mobile)) {
      setErrorMessage("Please enter a valid 10-digit mobile number.");
      return;
    }

    // Lazy-load reCAPTCHA only when needed and bind it to auth
    if (!window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              console.log("reCAPTCHA solved:", response);
            },
          },
          auth
        );
        console.log("reCAPTCHA initialized:", window.recaptchaVerifier);
      } catch (error) {
        console.error("Error initializing reCAPTCHA:", error);
        setErrorMessage("Failed to initialize reCAPTCHA.");
        return;
      }
    }

    try {
      const appVerifier = window.recaptchaVerifier;
      const formattedNumber = `+91${mobile}`;
      const result = await signInWithPhoneNumber(auth, formattedNumber, appVerifier);
      setConfirmationResult(result);
      setOtpSent(true);
      setErrorMessage("");
    } catch (error) {
      console.error("Error during OTP send:", error);
      setErrorMessage("Failed to send OTP. Please try again.");
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    }
  };

  const handleModalSubmit = async () => {
    if (!otp.trim()) {
      setErrorMessage("Please enter the OTP");
      return;
    }

    try {
      await confirmationResult.confirm(otp);
      setIsModalOpen(false);
      setOtp("");
      setOtpSent(false);
      setErrorMessage("");
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage("Failed to verify OTP. Please try again.");
    }
  };

  return (
    <header>
      <a className="logo" href="/">
        <img src={logo} alt="Jift Logo" />
      </a>
      <div className="search-bar-head">
        <input
          type="text"
          placeholder="Search here..."
          onChange={handleSearchChange}
        />
        <button className="search-button">
          <i className="fas fa-search"></i>
        </button>
      </div>
      <nav>
        <ul>
          <li>
            <a href="#">Free Listing</a>
          </li>
          {currentUser ? (
            <>
              <li>
                <span>Welcome, {currentUser.email}</span>
              </li>
              <li>
                <button className="logout-button" onClick={handleLogout}>
                  Logout
                </button>
              </li>
            </>
          ) : (
            <li>
              <button
                className="login-button-head"
                onClick={() => setIsModalOpen(true)}
              >
                Login / Sign Up
              </button>
            </li>
          )}
        </ul>
      </nav>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSendOTP={handleSendOTP}
        mobile={mobile}
        setMobile={setMobile}
        otp={otp}
        setOtp={setOtp}
        onSubmit={handleModalSubmit}
        errorMessage={errorMessage}
      />
      <div id="recaptcha-container"></div>
    </header>
  );
};

export default Header;
