import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { UserProvider } from "../components/Context/UserContext";
import "./css/TermCondition.css";

const TermCondition = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null,
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
          });
        },
        (error) => {
          setLocation((prevState) => ({ ...prevState, error: error.message }));
        }
      );
    } else {
      setLocation((prevState) => ({
        ...prevState,
        error: "Geolocation is not supported by this browser.",
      }));
    }
  }, []);

  return (
    <UserProvider>
      <div className="home">
        <Header setSearchTerm={setSearchTerm} />
        <div className="content">
          <div className="main-content">
            <h1>Terms and Conditions</h1>
            <p>
              <strong>Last updated: October 24, 2024</strong>
            </p>

            <section>
              <p>
                These Terms and Conditions ("Terms") govern your use of the Jift
                app ("Service") provided by Jift ("Company," "We," "Our," or
                "Us"). By accessing or using our Service, you agree to comply
                with and be bound by these Terms. Please read these Terms
                carefully before using the Service.
              </p>
            </section>

            <section>
              <h2>1. Agreement to Terms</h2>
              <p>
                By accessing or using the Service, you agree to be bound by
                these Terms and our Privacy Policy. If you do not agree with
                these Terms, you are not authorized to use the Service.
              </p>
            </section>

            <section>
              <h2>2. Use of Service</h2>
              <p>
                You must use the Service in compliance with applicable laws and
                regulations. You agree to not engage in any activity that may
                harm the functionality of the Service or other users’
                experience. This includes, but is not limited to, unauthorized
                access, misuse, and interference with the Service's performance.
              </p>
            </section>

            <section>
              <h2>3. Account Registration</h2>
              <p>
                To use certain features of the Service, you may be required to
                register for an account. You agree to provide accurate and
                complete information during registration and to keep your
                account details up to date. You are responsible for maintaining
                the confidentiality of your account information and are liable
                for all activities under your account.
              </p>
            </section>

            <section>
              <h2>4. Content</h2>
              <p>
                The content provided on the Service, including text, graphics,
                logos, images, and software, is the property of the Company or
                its content suppliers and is protected by copyright and other
                intellectual property laws. You are granted a limited,
                non-exclusive license to access and use the Service for
                personal, non-commercial purposes.
              </p>
            </section>

            <section>
              <h2>5. User Content</h2>
              <p>
                You may be able to submit or upload content to the Service,
                including but not limited to, messages, reviews, photos, and
                videos ("User Content"). By submitting User Content, you grant
                the Company a worldwide, royalty-free, sublicensable, and
                transferable license to use, display, and distribute such
                content as part of the Service.
              </p>
              <p>
                You represent and warrant that your User Content does not
                infringe the rights of others and complies with all applicable
                laws.
              </p>
            </section>

            <section>
              <h2>6. Prohibited Activities</h2>
              <p>
                You agree not to engage in any of the following activities while
                using the Service:
              </p>
              <ul>
                <li>Violating any local, state, or national laws.</li>
                <li>
                  Uploading or transmitting harmful content, including viruses
                  or malware.
                </li>
                <li>Impersonating another person or entity.</li>
                <li>Engaging in any fraudulent or misleading activity.</li>
                <li>
                  Interfering with or disrupting the functioning of the Service.
                </li>
              </ul>
            </section>

            <section>
              <h2>7. Termination</h2>
              <p>
                We may suspend or terminate your account and access to the
                Service if you violate these Terms. You can also terminate your
                account at any time by following the instructions within the
                Service. Upon termination, all licenses and rights granted to
                you will immediately cease.
              </p>
            </section>

            <section>
              <h2>8. Disclaimers</h2>
              <p>
                The Service is provided "as is" and without warranties of any
                kind, either express or implied. The Company does not warrant
                that the Service will be uninterrupted or error-free. We do not
                guarantee the accuracy, completeness, or reliability of the
                content available through the Service.
              </p>
            </section>

            <section>
              <h2>9. Limitation of Liability</h2>
              <p>
                To the maximum extent permitted by law, the Company is not
                liable for any damages, including but not limited to, direct,
                indirect, incidental, or consequential damages, arising from
                your use of or inability to use the Service.
              </p>
            </section>

            <section>
              <h2>10. Indemnification</h2>
              <p>
                You agree to indemnify and hold the Company, its officers,
                employees, agents, and affiliates harmless from any claims,
                damages, or losses arising from your use of the Service or your
                breach of these Terms.
              </p>
            </section>

            <section>
              <h2>11. Governing Law</h2>
              <p>
                These Terms will be governed by and construed in accordance with
                the laws of Karnataka, India, without regard to its conflict of
                law principles. Any disputes arising out of or relating to these
                Terms shall be subject to the exclusive jurisdiction of the
                courts in Karnataka, India.
              </p>
            </section>

            <section>
              <h2>12. Changes to Terms</h2>
              <p>
                The Company reserves the right to update or modify these Terms
                at any time. Any changes will be effective immediately upon
                posting on this page, with the "Last updated" date reflecting
                the update. We encourage you to review these Terms periodically.
              </p>
            </section>

            <section>
              <h2>13. Contact Us</h2>
              <p>
                If you have any questions regarding these Terms and Conditions,
                you can contact us by visiting our website or reaching out
                through the contact details provided in the app.
              </p>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </UserProvider>
  );
};

export default TermCondition;
