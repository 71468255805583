import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { UserProvider } from "../components/Context/UserContext";
import "./css/ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formStatus, setFormStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulating form submission (you can integrate an actual backend here)
    if (formData.name && formData.email && formData.message) {
      setFormStatus("Your message has been sent. We'll get back to you soon!");
      setFormData({ name: "", email: "", message: "" });
    } else {
      setFormStatus("Please fill out all fields.");
    }
  };

  return (
    <UserProvider>
      <div className="contact-us">
        <Header />
        <div className="content">
          <div className="main-content">
            <h1>Contact Us</h1>
            <p>If you have any questions or need assistance, please fill out the form below, and we'll get back to you as soon as possible.</p>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Your Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Your Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Your Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>

            {formStatus && <p className="form-status">{formStatus}</p>}
          </div>
        </div>
        <Footer />
      </div>
    </UserProvider>
  );
};

export default ContactUs;
