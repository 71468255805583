import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase'; // Import the Firestore DB instance
import { collection, getDocs } from 'firebase/firestore';
import defaultProfile from '../../../images/logo.png'; // Import default image

function Shops() {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Track modal open/close
  const [selectedShop, setSelectedShop] = useState(null); // Track the selected shop data

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'Shops'); // Reference to Shops collection
      const usersSnapshot = await getDocs(usersCollection); // Get all documents
      const usersList = usersSnapshot.docs.map(doc => doc.data()); // Map to get shop data
      setUsers(usersList); // Set the state with the fetched data
    };

    fetchUsers();
  }, []);

  const handleShopClick = (shop) => {
    setSelectedShop(shop); // Set the selected shop data
    setIsModalOpen(true); // Open modal on shop click
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div style={styles.container}>
      <ul style={styles.list}>
        {users.length > 0 ? (
          users.map((user, index) => (
            <li key={index} style={styles.listItem} onClick={() => handleShopClick(user)}>
              <img 
                src={user.shopImage || user.profilePic || defaultProfile} // Use default if both are missing
                alt="Profile" 
                style={styles.image} 
              />
              <div style={styles.info}>
                <h4 style={styles.shopName}>{user.shopName || user.ownerName}</h4>
                <p style={styles.phone}>Phone: {user.phone}</p>
              </div>
            </li>
          ))
        ) : (
          <p style={styles.noUsers}>No users found</p>
        )}
      </ul>

      {/* Modal */}
{isModalOpen && selectedShop && (
  <div style={styles.modal}>
    <div style={styles.modalContent}>
      <button onClick={handleCloseModal} style={styles.closeButton}>Close</button>

      {/* Conditional Shop Header Image */}
      {selectedShop.shopImage && (
        <div style={styles.modalHeader}>
          <img 
            src={selectedShop.shopImage} 
            alt={`${selectedShop.shopName || selectedShop.ownerName} Header`} 
            style={styles.modalHeaderImage} 
          />
        </div>
      )}

      <div style={styles.profileContainer}>
        <img 
          src={selectedShop.profilePic || defaultProfile} 
          alt="Profile" 
          style={styles.profileImage} 
        />
        <h2 style={styles.shopTitle}>{selectedShop.shopName || selectedShop.ownerName}</h2>
      </div>

      <p>{selectedShop.bio || "No bio available."}</p>
      <p><strong>Phone:</strong> {selectedShop.phone}</p>
      <p><strong>Email:</strong> {selectedShop.email || "Not available"}</p>
      <p><strong>Address:</strong> {selectedShop.address}</p>

      <div style={styles.imageGrid}>
        {selectedShop.shopPic && selectedShop.shopPic.map((image, idx) => (
          <img key={idx} src={image} alt={`Shop ${idx}`} style={styles.shopImage} />
        ))}
      </div>
    </div>
  </div>
)}


    </div>
  );
}

// Modal Styles
const styles = {
  container: {
    width: '100%',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    boxSizing: 'border-box',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    marginBottom: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  },
  image: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginRight: '15px',
    border: '2px solid #e0e0e0',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  shopName: {
    fontSize: '1.1em',
    margin: '0 0 5px',
    color: '#333',
  },
  phone: {
    margin: 0,
    color: '#666',
  },
  noUsers: {
    textAlign: 'center',
    color: '#999',
    fontStyle: 'italic',
  },
  // Modal Styles
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    transition: 'all 0.3s ease-in-out',
    overflow: 'auto', // Allow scrolling when the content is too large
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '600px',
    width: '100%',
    maxHeight: '80vh', // Limit max height of the modal content
    overflowY: 'auto', // Enable vertical scroll if the content exceeds maxHeight
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  closeButton: {
    padding: '10px 20px',
    backgroundColor: '#f44336',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  // Image Grid Styles for 5x4 grid
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)', // 5 columns
    gridTemplateRows: 'repeat(4, 1fr)', // 4 rows
    gap: '10px', // Space between images
    marginTop: '10px',
  },
  shopImage: {
    width: '100%', // Make each image take up 100% of its grid cell
    height: '200px',
    objectFit: 'cover', // Ensure the images fit inside the grid cells
    borderRadius: '8px',
  },
  modalHeader: {
    width: '100%',
    height: '200px',
    overflow: 'hidden',
    borderRadius: '8px 8px 0 0', // Rounded corners at the top
    marginBottom: '20px',
  },
  modalHeaderImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensure the image covers the header area
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  profileImage: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    marginRight: '15px',
    border: '2px solid #ddd',
    objectFit: 'cover',
  },
  shopTitle: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    color: '#333',
    margin: 0,
  },
};


export default Shops;
