export const haversineDistance = (coords1, coords2) => {
    // Debug the inputs
    //console.log('Coordinates 1:', coords1);
    //console.log('Coordinates 2:', coords2);
  
    // Check if both coordinates are provided and are valid
    if (!coords1 || !coords2 || typeof coords1.latitude !== 'number' || typeof coords1.longitude !== 'number' || typeof coords2.latitude !== 'number' || typeof coords2.longitude !== 'number') {
      console.error('Invalid coordinates provided:', { coords1, coords2 });
      return 0; // Return 0 if coordinates are invalid
    }
  
    // Convert degrees to radians
    function toRad(x) {
      return (x * Math.PI) / 180;
    }
  
    const lat1 = coords1.latitude;
    const lon1 = coords1.longitude;
    const lat2 = coords2.latitude;
    const lon2 = coords2.longitude;
  
    //console.log('Latitude and Longitude values:', { lat1, lon1, lat2, lon2 });
  
    // Radius of Earth in kilometers
    const R = 6371;
  
    // Differences in coordinates
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
  
    // Haversine formula
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
  
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
  
    //console.log('Calculated Distance:', distance);
  
    return parseFloat(distance.toFixed(2)); // Return distance as a number with 2 decimal places
  };
  