import React, { useContext } from "react";
import "./css/Badge.css";
import { LocationContext } from "./Context/LocationProvider"; // Import the LocationContext

const Badge = ({category, onClearCategory }) => {
  const { location } = useContext(LocationContext); // Access location from context

  if (!category || !location) return null; // Render nothing if no category or city

  return (
    <div className="category-badge-wrapper">
      <span className="category-breadcrumb">
        Best <strong>{category}</strong> in <strong>{location.city}</strong>
      </span>
      <button
        className="close-button"
        onClick={onClearCategory}
        aria-label="Close category"
      >
        &times;
      </button>
    </div>
  );
};

export default Badge;
