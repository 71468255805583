import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { UserProvider } from "../components/Context/UserContext";
import "./css/Privacy.css";

const Privacy = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null,
  });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
          });
        },
        (error) => {
          setLocation((prevState) => ({ ...prevState, error: error.message }));
        }
      );
    } else {
      setLocation((prevState) => ({
        ...prevState,
        error: "Geolocation is not supported by this browser.",
      }));
    }
  }, []);

  return (
    <UserProvider>
      <div className="home">
        <Header setSearchTerm={setSearchTerm} />
        <div className="content">
          <div className="main-content">
          <div class="container">
    <header>
      <h1>Privacy Policy</h1>
      <p><strong>Last updated: October 24, 2024</strong></p>
    </header>

    <section>
      <p>
        This Privacy Policy outlines our policies and procedures on the collection, use, and disclosure of your information when using the Jift app. It also informs you about your privacy rights and how the law protects you. By using the Jift app, you agree to the collection and use of your information as described in this Privacy Policy.
      </p>
    </section>

    <section>
      <h2>Interpretation and Definitions</h2>
      
      <h3>Interpretation</h3>
      <p>The words with capitalized initials have meanings defined under the following conditions. These definitions shall have the same meaning whether they appear in singular or plural form.</p>
      
      <h3>Definitions</h3>
      <ul>
        <li><strong>Account:</strong> A unique account created for you to access the Service or parts of our Service.</li>
        <li><strong>Application:</strong> Refers to Jift, the mobile app provided by the Company.</li>
        <li><strong>Company:</strong> Refers to Jift (referred to as "We," "Us," or "Our" in this Agreement).</li>
        <li><strong>Country:</strong> Refers to Karnataka, India.</li>
        <li><strong>Device:</strong> Any device capable of accessing the Service, such as a smartphone, tablet, or computer.</li>
        <li><strong>Personal Data:</strong> Any information relating to an identified or identifiable individual.</li>
        <li><strong>Service:</strong> Refers to the Jift mobile application.</li>
        <li><strong>Service Provider:</strong> A third party who processes data on behalf of the Company.</li>
        <li><strong>Third-Party Social Media Service:</strong> Websites or social networks through which you can log in or create an account to use the Service.</li>
        <li><strong>Usage Data:</strong> Data collected automatically, either generated by using the Service or from the Service's infrastructure (e.g., the duration of a page visit).</li>
        <li><strong>You:</strong> The individual accessing or using the Service.</li>
      </ul>
    </section>

    <section>
      <h2>Collecting and Using Your Personal Data</h2>

      <h3>Types of Data Collected</h3>
      <h4>Personal Data</h4>
      <p>
        While using our Service, we may ask you to provide certain personally identifiable information, including but not limited to:
      </p>
      <ul>
        <li>Mobile number (with OTP verification)</li>
        <li>Email address (with OTP verification)</li>
        <li>Profile picture</li>
        <li>First name and last name</li>
        <li>Location data (for shop discovery)</li>
      </ul>

      <h4>Usage Data</h4>
      <p>
        Usage Data is collected automatically when using the Service. This includes information such as your Device's IP address, browser type, and version, as well as data related to app usage, such as the shops you view, the time and date of visits, and unique device identifiers.
      </p>

      <h3>Information Collected While Using the Application</h3>
      <p>While using our Application, we may collect the following information:</p>
      <ul>
        <li><strong>Location Information:</strong> With your permission, we collect location data to help you discover shops around you and display shop locations on Google Maps.</li>
        <li><strong>Mobile Number:</strong> We use your mobile number to register your account via OTP verification.</li>
        <li><strong>Profile Picture and Personal Information:</strong> You can upload a profile picture and update your mobile number or email address, which may also be subject to OTP verification.</li>
        <li><strong>Communication Features:</strong> The app allows users to chat directly with shops or make phone calls to shops listed. These communications may be facilitated through our Service but are carried out via your device's communication services (phone, SMS).</li>
      </ul>
      <p>You can enable or disable these permissions anytime through your device settings.</p>
    </section>

    <section>
      <h2>Use of Your Personal Data</h2>
      <p>We may use your Personal Data for the following purposes:</p>
      <ul>
        <li>To provide and maintain our Service: Including enabling features like location-based shop discovery, communication via chat or phone with shops, and viewing shop locations on maps.</li>
        <li>To manage your Account: Manage your registration via mobile number and OTP, including profile updates such as changing your mobile number or email with verification.</li>
        <li>To contact you: Send notifications, including OTPs for verification or updates about changes to our services.</li>
        <li>To monitor the use of our Service: Analyze user activity to improve the app experience and performance.</li>
        <li>For security and legal purposes: Protect against fraud and ensure compliance with legal obligations.</li>
      </ul>
    </section>

    <section>
      <h2>Sharing and Disclosure of Your Personal Data</h2>
      <p>We may share your Personal Data under the following conditions:</p>
      <ul>
        <li><strong>With Service Providers:</strong> We may share your personal information with third-party service providers that help us operate the Service (e.g., mapping services like Google Maps).</li>
        <li><strong>For Legal Reasons:</strong> We may disclose your data to comply with legal obligations, protect the Company’s rights, or prevent harm to users.</li>
        <li><strong>With your consent:</strong> We may share your data for any other purposes only with your explicit consent.</li>
      </ul>
    </section>

    <section>
      <h2>Retention of Your Personal Data</h2>
      <p>We will retain your Personal Data only for as long as necessary for the purposes outlined in this policy, including legal, security, and operational reasons.</p>
    </section>

    <section>
      <h2>Transfer of Your Personal Data</h2>
      <p>Your data may be transferred to locations outside of your jurisdiction for processing. We will ensure that adequate protections are in place before transferring any of your Personal Data.</p>
    </section>

    <section>
      <h2>Security of Your Personal Data</h2>
      <p>We take the security of your data seriously, but no method of transmission over the Internet or electronic storage is completely secure. We strive to use commercially reasonable means to protect your Personal Data but cannot guarantee its absolute security.</p>
    </section>

    <section>
      <h2>Children’s Privacy</h2>
      <p>Our Service is not intended for anyone under the age of 13, and we do not knowingly collect Personal Data from children under 13. If you are a parent or guardian and believe that your child has provided us with Personal Data, please contact us.</p>
    </section>

    <section>
      <h2>Links to Other Websites</h2>
      <p>Our Service may contain links to third-party websites or services. We are not responsible for the privacy practices of these external sites.</p>
    </section>

    <section>
      <h2>Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be notified via updates within the application or through email notifications.</p>
    </section>

    <section>
      <h2>Contact Us</h2>
      <p>If you have any questions regarding this Privacy Policy, you can contact us by visiting our website.</p>
    </section>
  </div>
          </div>
        </div>
        <Footer />
      </div>
    </UserProvider>
  );
};

export default Privacy;
